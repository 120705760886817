import { ProductInfo } from "./ProductInfo";
import { PaymentForm } from "./PaymentForm";
import { useContext, useMemo, useState } from "react";
import { AddressConfirmation } from "./Address";
import { useUserData } from "../../../../../../../hooks/state/useUserData";
import { DriverPaymentGatewayContext } from "../../../../../contexts/DriverPaymentGatewayContext";
import { SELECT_SUB_PRODUCT } from "../../../../../../../services/graphql/driver/mutations";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { PaymentContext } from "../../../../../../../contexts/PaymentContext";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../../../../../../electrify_frontend_common/icons/CloseIcon";
import { useTranslation } from "react-i18next";
import { NoValidTariffForSelectedProduct } from "./ProductInfo/NoValidTariffForSelectedProduct";


function PaymentAgreement() {

    const { t } = useTranslation("driverPaymentSystem");

    const { user, refreshUserAccountInfo } = useUserData();
    const navigate = useNavigate();

    const { agreeWithPayment, closePaymentModal } = useContext(DriverPaymentGatewayContext);

    const [selectSubProduct] = useMutation(SELECT_SUB_PRODUCT, {
        variables: {
            product: null
        },
        onCompleted: () => {
            navigate("/");
            refreshUserAccountInfo();
        },
    });


    return (
        <div className="flex flex-col h-full">
            <div className="flex w-full justify-end p-8">
                <CloseIcon className="cursor-pointer" onClick={() => closePaymentModal()} />
            </div>
            <div className="flex flex-col h-full justify-center">
                <div className="flex flex-col items-center justify-center p-32">
                    <Button
                        type="button"
                        onClick={agreeWithPayment}
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LARGE}
                        className="w-full my-1"
                    >
                        {t("agreement.proceedToPayment")}
                    </Button>

                    <Button
                        type="button"
                        onClick={() => closePaymentModal()}
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LARGE}
                        className="w-full my-1"
                    >
                        {t("agreement.askMeLater")}
                    </Button>

                    <Button
                        type="button"
                        onClick={selectSubProduct}
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LARGE}
                        className="w-full my-1"
                    >
                        {t("agreement.dontAskAgain")}
                    </Button>
                </div>
            </div>
        </div>
    )

}


export function CheckoutForm() {


    const { agreedToProceed } = useContext(DriverPaymentGatewayContext);

    const { clientSecret, selectedTariff, noMatchBetweenPreselectedProductAndValidTarifs } = useContext(PaymentContext);


    const RightSide = useMemo(() => {
        if (agreedToProceed) return <>
            {clientSecret ? (
                <PaymentForm clientSecret={clientSecret} />
            ) : (
                <AddressConfirmation />
            )}
        </>
        return <PaymentAgreement />

    }, [clientSecret, agreedToProceed])

    if (noMatchBetweenPreselectedProductAndValidTarifs) {
        return (
            <NoValidTariffForSelectedProduct />
        )
    }

    return (
        <>
            {selectedTariff ? (
                <form className="flex flex-col w-full h-full overflow-hidden">
                    <div className="grid grid-cols-2 h-full">
                        <ProductInfo tariff={selectedTariff} />

                        <>{RightSide}</>
                    </div>
                </form>
            ) : <div>Something went wrong. Contact support</div>}
        </>
    );
}
