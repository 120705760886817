import { useTranslation } from "react-i18next";
import FavoriteSelectedIcon from "../../../../assets/favorite_selected.svg";
import FavoriteIcon from "../../../../assets/favorite.svg";
import { Tooltip } from "../../Tooltip";
import { AlternativeVehicle } from "../../../types";



export default function FavoriteButton({
    alternativeVehicle,
    removeFromFavorites,
    addToFavorites,
    isFavorited
}: {
    alternativeVehicle: Partial<AlternativeVehicle>;
    removeFromFavorites: () => void;
    addToFavorites: () => void;
    isFavorited: boolean;
}) {

    const { t } = useTranslation("evFinder");

    return (
        <Tooltip
            content={
                <div className="whitespace-nowrap">
                    {isFavorited
                        ? t("favorite.tooltip.remove")
                        : t("favorite.tooltip.add")}
                </div>
            }
            placement="right-bottom"
        >
            <img
                className="cursor-pointer h-4"
                alt="favorite-button"
                src={isFavorited ? FavoriteSelectedIcon : FavoriteIcon}
                onClick={(e) => {
                    e.stopPropagation();
                    alternativeVehicle?.isFavorited ? removeFromFavorites() : addToFavorites();
                    return;
                }}
            />
        </Tooltip>
    );
}
