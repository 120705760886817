import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useScreenSize } from "../../../../../../electrify_frontend_common/hooks/useScreenSize";
import { AlternativeEletricVehicleDetails } from "../../../../../../@types";
import { getEvDetails } from "../../../../../../electrify_frontend_common/services";
import { CarIconSelector } from "../../../../../../electrify_frontend_common/components/CarIconSelector";



export function useOpenEvDetails({evId} : {evId : string}) {

    const userCountryCode: string = "DE"; // Should be taken from either the IP of user or the country set for the device of user in the future

    const { isMobileView } = useScreenSize();

    const { i18n, t } = useTranslation("evFinder");
    const localeCode = i18n.language;
    const currencySymbol = "€";

    const [vehicleData, setVehicleData] =
        useState<AlternativeEletricVehicleDetails | null>(null);

    const mainStats = vehicleData
        ? {
              energyConsumption:
                  vehicleData?.evModelData?.technicalData?.vehicleConsumption100Km?.toLocaleString(
                      localeCode
                  ),
              realRange:
                  vehicleData?.evModelData?.range?.expectedRealRangeAverage?.toLocaleString(
                      localeCode
                  ),
              energyCost:
                  vehicleData?.yearlyFuelAndEnergyCosts?.toLocaleString(
                      localeCode
                  ),
              listPrice: vehicleData?.listPrice?.toLocaleString(localeCode),
          }
        : null;

    const headerTitle = vehicleData
        ? `${vehicleData?.make} ${vehicleData?.model}`
        : null;


    useQuery([], () => getEvDetails(evId, userCountryCode || "DE"), {
        retry: false,
        onSuccess: (data) => {
            setVehicleData(data);
        },
        onError: (error) => {
            setVehicleData(null);
        },
    });

    const replaceImgWithError = (e: any) => {
        e.target.onerror = null;
        e.target.src = CarIconSelector(
            vehicleData?.bodyStyle,
            vehicleData?.category
        );
    };

    return {
        isMobileView,
        mainStats,
        headerTitle,
        currencySymbol,
        vehicleData,
        replaceImgWithError,
        t,
    };
}
