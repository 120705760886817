import { ReactComponent as ErrorImage } from "../../../assets/error-image.svg";
import { Button } from "../../components/misc/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../components/misc/Buttonv2/types";

export function GenericErrorPage({ title, text, onClickButton, buttonText }: { title: string, text: string, onClickButton: () => void, buttonText: string }) {

  return (
    <div className="w-full flex-col  px-8 m-auto justify-center items-center">
      <div className="flex w-full justify-center mt-8 mb-12">
        <ErrorImage />
      </div>
      <div className="w-full flex justify-center">
        <h1 className='text-3xl text-Black-default mb-4'>{title}</h1>
      </div>

      <div className='mb-10 flex text-base text-Grey-shade text-center'>
        <p >{text}</p>
      </div>
      <div className="mt-8">
        <Button
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          onClick={onClickButton}
          className="w-full"
        >
          {buttonText}
        </Button>
      </div>

    </div>
  )
}
