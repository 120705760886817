import { createContext, Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react";
import { SimulationVehicleInfo } from "../../../../../../../@types";
import { GET_VEHICLE_SELECTED_EV } from "../../../../../../../services/graphql/fleet/queries/simulation";
import { ApolloQueryResult, OperationVariables, useMutation, useQuery } from "@apollo/client";
import { ADD_NEW_FAVORITE_EV, REMOVE_FAVORITE_EV, UPDATE_SELECTED_EV } from "../../../../../../../services/graphql/fleet/mutations/simulation";
import toast from "react-hot-toast";
import { EVAlternativesResponse } from "../../../../../../../@types/simulations";
import { useAlternativeVehicles } from "../../../../../../B2B/Electrify/pages/Simulation/FleetVehicleComparatorList/AlternativesModal/hooks/useAlternativeVehicles";
import { AlternativeVehicle } from "../../../../../../../electrify_frontend_common/types";
import { useTranslation } from "react-i18next";


export const DriverEVFinderContext = createContext<{
  evFinderData: {
    vehicleEvAlternatives: EVAlternativesResponse;
  } | undefined,
  evFinderFavoritesData: {vehicleEvAlternatives: EVAlternativesResponse;
  } | undefined,
  loading: boolean,
  sortBy: string,
  setSortBy: (sortBy: string) => void,
  currentPage: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  favoritesOnly: boolean,
  setFavoritesOnly: (favoritesOnly: boolean) => void,
  alternativeEvsCount: { vehicleEvAlternativesCount: { totalCount: number, favoritesCount: number } },
  filtersHandler: any,
  refetchEvAlternatives: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{ vehicleEvAlternatives: EVAlternativesResponse }>>,
  resetFiltersAndRefetch: () => void,
  recountVehicles: () => void,
  selectedEvLoading: boolean,
  removeAlternativeVehicle: (vehicleId: string) => void,
  selectAlternativeVehicle: (selectedEvId: string) => void,
  selectedAlternativeElectricVehicle: Partial<AlternativeVehicle> | null,
  evDetailQueryParams: string | null
  openEvDetailsModal: ( evId: string ) => void,
  closeEvDetailsModal: () => void,
  removeFavoriteVehicle: (evIdToRemove: string) => void,
  addNewFavoriteVehicle: (evIdToAdd: string) => void
}>({} as any);



export function DriverEVFinderContextProvider({ children, vehicle }: { children: ReactElement, vehicle: SimulationVehicleInfo }) {

  // const [selectedAlternativeElectricVehicle, setSelectedAlternativeElectricVehicle] = useState<Partial<AlternativeVehicle> | null>(null);
  const [evDetailQueryParams, setEvDetailQueryParams] = useState<string | null>(null);

  const {t} = useTranslation("evFinder");

  const {
    evFinderData,
    evFinderFavoritesData,
    loading,
    sortBy,
    setSortBy,
    currentPage,
    setCurrentPage,
    favoritesOnly,
    setFavoritesOnly,
    alternativeEvsCount,
    filtersHandler,
    refetchEvAlternatives,
    refetchFavorites,
    resetFiltersAndRefetch,
    recountVehicles
  } = useAlternativeVehicles(vehicle.vehicleId);


  const { data: selectedEv, refetch: refetchSelectedEv, loading: selectedEvLoading } = useQuery<{
    vehicleSelectedEv: any;
  }>(GET_VEHICLE_SELECTED_EV, {
    variables: {
      vehicleId: vehicle.vehicleId,
      renewCalculation: false,
    },
    
  });

  const [updateSelectedEv] = useMutation(UPDATE_SELECTED_EV, {
    onCompleted: (data) => {
      refetchSelectedEv();
      refetchEvAlternatives();
      refetchFavorites();
      toast.success("Selectd EV updated successfully successfully");
    },
  });

  const removeAlternativeVehicle = () => {
    updateSelectedEv({
      variables: {
        vehicleId: vehicle.vehicleId,
        selectedEvId: null,
      },
    });
    refetchSelectedEv();
  };

  const selectAlternativeVehicle = (selectedEvId: string) => {
    updateSelectedEv({
      variables: {
        vehicleId: vehicle.vehicleId,
        selectedEvId: selectedEvId,
      },
    });
  };


  const [addNewFavoriteVehicleMutation] = useMutation(ADD_NEW_FAVORITE_EV, {
    onCompleted: () => {
      recountVehicles();
      refetchFavorites();
      toast.success(t("favorite.addedToast"));
    },
  });

  const [removeFavoriteVehicleMutation] = useMutation(REMOVE_FAVORITE_EV, {
    onCompleted: () => {
      recountVehicles();
      refetchFavorites();
      toast.success(t("favorite.removedToast"));
    },
  });

  const removeFavoriteVehicle = (evIdToRemove: string) => {
    removeFavoriteVehicleMutation({
      variables: {
        vehicleId: vehicle.vehicleId,
        evIdToRemove: evIdToRemove,
      },
    });
  };

  const addNewFavoriteVehicle = (evIdToAdd: string) => {
    addNewFavoriteVehicleMutation({
      variables: {
        vehicleId: vehicle.vehicleId,
        evIdToAdd: evIdToAdd,
      },
    });
  };




  return (
    <DriverEVFinderContext.Provider value={{
      evFinderFavoritesData,
      evFinderData,
      loading,
      sortBy,
      setSortBy,
      currentPage,
      setCurrentPage,
      favoritesOnly,
      setFavoritesOnly,
      alternativeEvsCount,
      filtersHandler,
      refetchEvAlternatives,
      resetFiltersAndRefetch,
      selectedEvLoading,
      removeAlternativeVehicle,
      selectAlternativeVehicle,
      selectedAlternativeElectricVehicle : selectedEv?.vehicleSelectedEv?.selectedEv,
      evDetailQueryParams,
      recountVehicles,
      openEvDetailsModal: (evId: string) => {
        setEvDetailQueryParams(evId);
      },
      closeEvDetailsModal: () => {
        setEvDetailQueryParams(null);
      },
      removeFavoriteVehicle,
      addNewFavoriteVehicle
    }}>
      {children}
    </DriverEVFinderContext.Provider>
  )


}