import { Table } from "./components";
import { ReactComponent as CoreDataIcon } from "../../../assets/ev_details/core-data.svg";
import { ReactComponent as RangeIcon } from "../../../assets/ev_details/range.svg";
import { ReactComponent as ChargingIcon } from "../../../assets/ev_details/charging.svg";
import { ReactComponent as MiscellaneousIcon } from "../../../assets/ev_details/miscellaneous.svg";
import { useTranslation } from "react-i18next";
import { AlternativeEletricVehicleDetails, DriveTrainPropulsion } from "../../types";



export function VehicleDetailsTable({ vehicleData }: { vehicleData: AlternativeEletricVehicleDetails | null }) {


    const { t, i18n } = useTranslation("evFinder");
    const localeCode = i18n.language;


    const translateAvailability = (availability?: string): string => {
        switch (availability) {
            case "CURRENT":
                return t("vehicleDetailedView.coreData.availabilityStatus.current");
            case "FUTURE":
                return t("vehicleDetailedView.coreData.availabilityStatus.future");
            case "PAST":
                return t("vehicleDetailedView.coreData.availabilityStatus.past");
            default:
                return "?"
        }
    }

    const translateBatteryType = (batteryType?: string): string => {
        switch (batteryType) {
            case "LITHIUM_ION":
                return t("vehicleDetailedView.battery.types.lithiumIon");
            default:
                return "?"
        }
    }

    const translatePropulsion = (propulsion?: string): string => {
        switch (propulsion) {
            case DriveTrainPropulsion.FWD:
                return t("vehicleDetailedView.technicalData.propulsions.frontWheelDrive");
            case DriveTrainPropulsion.RWD:
                return t("vehicleDetailedView.technicalData.propulsions.rearWheelDrive");
            case DriveTrainPropulsion["4x4"]:
                return t("vehicleDetailedView.technicalData.propulsions.allWheelDrive");
            default:
                return '?'
        }
    }

    const translatePortLocations = (location?: string): string => {
        switch (location) {
            case "FRONT_LEFT":
                return t("vehicleDetailedView.charging.portLocations.frontLeft");
            case "FRONT_RIGHT":
                return t("vehicleDetailedView.charging.portLocations.frontRight");
            case "FRONT_MIDDLE":
                return t("vehicleDetailedView.charging.portLocations.frontMiddle");
            case "MIDDLE_LEFT":
                return t("vehicleDetailedView.charging.portLocations.middleLeft");
            case "MIDDLE_RIGHT":
                return t("vehicleDetailedView.charging.portLocations.middleRight");
            case "REAR_LEFT":
                return t("vehicleDetailedView.charging.portLocations.rearLeft");
            case "REAR_RIGHT":
                return t("vehicleDetailedView.charging.portLocations.rearRight");
            default:
                return "?"
        }
    }


    const translateBooleanValues = (values: boolean | undefined): string => {
        return values ? t("vehicleDetailedView.booleanValues.yes") : t("vehicleDetailedView.booleanValues.no");
    }


    const coreData = vehicleData
        ? [
            {
                prop: t("vehicleDetailedView.coreData.availability"),
                value: translateAvailability(vehicleData?.evModelData?.coreData?.isAvailable)
            },
            {
                prop: t("vehicleDetailedView.coreData.acChargePower"),
                value: vehicleData?.evModelData?.coreData?.acChargePower?.toLocaleString(
                    localeCode
                ),
                units: "kW",
            },
            {
                prop: t("vehicleDetailedView.coreData.dcChargeMaxPower"),
                value: vehicleData?.evModelData?.coreData?.dcChargeMaxPower?.toLocaleString(
                    localeCode
                ),
                units: "kW",
            },
            // { prop: "Energy comsumption", value: vehicleData?.evModelData?.coreData?.energyConsumption },
        ]
        : null;

    const technicalData = vehicleData
        ? [
            {
                prop: t("vehicleDetailedView.technicalData.acceleration"),
                value: vehicleData?.evModelData?.technicalData?.acceleration?.toLocaleString(
                    localeCode
                ),
                units: "sec",
            },
            {
                prop: t("vehicleDetailedView.technicalData.topSpeed"),
                value: vehicleData?.evModelData?.technicalData?.topSpeed?.toLocaleString(
                    localeCode
                ),
                units: "km/h",
            },
            {
                prop: t("vehicleDetailedView.technicalData.totalPower"),
                value: vehicleData?.evModelData?.technicalData?.totalPower?.toLocaleString(
                    localeCode
                ),
                units:
                    "kW (" +
                    vehicleData?.evModelData?.technicalData?.totalPowerHp?.toLocaleString(
                        localeCode
                    ) +
                    "PS)",
            },
            {
                prop: t("vehicleDetailedView.technicalData.totalTorque"),
                value: vehicleData?.evModelData?.technicalData?.totalTorque?.toLocaleString(
                    localeCode
                ),
                units: "Nm",
            },

            {
                prop: t("vehicleDetailedView.technicalData.drive"),
                value: translatePropulsion(vehicleData?.evModelData?.technicalData?.propulsion),
            },
            {
                prop: t("vehicleDetailedView.technicalData.vehicleConsumption"),
                value: vehicleData?.evModelData?.technicalData?.vehicleConsumption100Km?.toLocaleString(
                    localeCode
                ),
                units: "kWh",
            },
        ]
        : null;

    const rangeData = vehicleData
        ? [
            {
                prop: t("vehicleDetailedView.range.expectedRealRange"),
                value: vehicleData?.evModelData?.range?.expectedRealRangeAverage?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.expectedRealRangeHighwayCold"),
                value: vehicleData?.evModelData?.range?.expectedRealRangeHighwayCold?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.expectedRealRangeCityCold"),
                value: vehicleData?.evModelData?.range?.expectedRealRangeCityCold?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.expectedRealRangeCombinedCold"),
                value: vehicleData?.evModelData?.range?.expectedRealRangeCombinedCold?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.expectedRealRangeHighwayMild"),
                value: vehicleData?.evModelData?.range?.expectedRealRangeHighwayMild?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.expectedRealRangeCityMild"),
                value: vehicleData?.evModelData?.range?.expectedRealRangeCityMild?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.expectedRealRangeCombinedMild"),
                value: vehicleData?.evModelData?.range?.expectedRealRangeCombinedMild?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.rangeWltpLow"),
                value: vehicleData?.evModelData?.range?.rangeWLTPLow?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
            {
                prop: t("vehicleDetailedView.range.rangeWltpHigh"),
                value: vehicleData?.evModelData?.range?.rangeWLTPHigh?.toLocaleString(
                    localeCode
                ),
                units: "km",
            },
        ]
        : null;

    const batteryData = vehicleData
        ? [
            {
                prop: t("vehicleDetailedView.battery.nominalCapacity"),
                value: vehicleData?.evModelData?.battery?.nominalCapacity?.toLocaleString(
                    localeCode
                ),
                units: "kWh",
            },
            {
                prop: t("vehicleDetailedView.battery.useableCapacity"),
                value: vehicleData?.evModelData?.battery?.usableCapacity?.toLocaleString(
                    localeCode
                ),
                units: "kWh",
            },
            {
                prop: t("vehicleDetailedView.battery.batteryType"),
                value: translateBatteryType(vehicleData?.evModelData?.battery?.batteryType),
            },
        ]
        : null;

    const chargingData = vehicleData
        ? [
            {
                prop: t("vehicleDetailedView.charging.acChargePort"),
                value: vehicleData?.evModelData?.charging?.acChargePort,
            },
            {
                prop: t("vehicleDetailedView.charging.acPortLocation"),
                value: translatePortLocations(vehicleData?.evModelData?.charging?.acPortLocation),
            },
            {
                prop: t("vehicleDetailedView.charging.acChargePower"),
                value: vehicleData?.evModelData?.charging?.acChargePower?.toLocaleString(
                    localeCode
                ),
                units: "kW",
            },
            {
                prop: t("vehicleDetailedView.charging.acChargeTime"),
                value: vehicleData?.evModelData?.charging?.acChargeTime?.toLocaleString(
                    localeCode
                ),
                units: "min",
            },
            {
                prop: t("vehicleDetailedView.charging.dcChargePort"),
                value: vehicleData?.evModelData?.charging?.dcChargePort,
            },
            {
                prop: t("vehicleDetailedView.charging.dcPortLocation"),
                value: translatePortLocations(vehicleData?.evModelData?.charging?.dcPortLocation),
            },
            {
                prop: t("vehicleDetailedView.charging.dcChargePower"),
                value: vehicleData?.evModelData?.charging?.dcChargePower?.toLocaleString(
                    localeCode
                ),
                units: "kW",
            },
            {
                prop: t("vehicleDetailedView.charging.dcChargeTime"),
                value: vehicleData?.evModelData?.charging?.dcChargeTime?.toLocaleString(
                    localeCode
                ),
                units: "min",
            },
        ]
        : null;

    const dimensionsAndWeightData = vehicleData
        ? [
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.length"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.length?.toLocaleString(
                    localeCode
                ),
                units: "mm",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.width"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.width?.toLocaleString(
                    localeCode
                ),
                units: "mm",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.widthWithMirrors"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.widthWithMirrors?.toLocaleString(
                    localeCode
                ),
                units: "mm",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.height"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.height?.toLocaleString(
                    localeCode
                ),
                units: "mm",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.wheelbase"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.wheelbase?.toLocaleString(
                    localeCode
                ),
                units: "mm",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.weightUnladen"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.weightUnladen?.toLocaleString(
                    localeCode
                ),
                units: "kg",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.grossVehicleWeight"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.grossVehicleWeight?.toLocaleString(
                    localeCode
                ),
                units: "kg",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.maxPayload"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.maxPayload?.toLocaleString(
                    localeCode
                ),
                units: "kg",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.cargoVolume"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.cargoVolume?.toLocaleString(
                    localeCode
                ),
                units: "l",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.cargoVolumeMax"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.cargoVolumeMax?.toLocaleString(
                    localeCode
                ),
                units: "l",
            },
            {
                prop: t("vehicleDetailedView.dimensionsAndWeight.cargoVolumeFrunk"),
                value: vehicleData?.evModelData?.dimensionsAndWeight?.cargoVolumeFrunk?.toLocaleString(
                    localeCode
                ),
                units: "l",
            },
        ]
        : null;

    const miscellaneousData = vehicleData
        ? [
            {
                prop: t("vehicleDetailedView.miscellaneous.towHitchPossible"),
                value: translateBooleanValues(vehicleData?.evModelData?.miscellaneous?.towHitchPossible)
            },
            {
                prop: t("vehicleDetailedView.miscellaneous.seats"),
                value: vehicleData?.evModelData?.miscellaneous?.seats,
            },
            {
                prop: t("vehicleDetailedView.miscellaneous.isofix"),
                value: vehicleData?.evModelData?.miscellaneous?.isofixSeats ?
                    t("vehicleDetailedView.miscellaneous.isofixCount", { count: vehicleData?.evModelData?.miscellaneous?.isofixSeats })
                    : t("vehicleDetailedView.booleanValues.no"),
            },
            {
                prop: t("vehicleDetailedView.miscellaneous.turningCircle"),
                value: vehicleData?.evModelData?.miscellaneous?.turningCircle?.toLocaleString(
                    localeCode
                ),
                units: "m",
            },
        ]
        : null;

    return (
        <>
            <Table
                title={t("vehicleDetailedView.coreData.title")}
                Icon={<CoreDataIcon />}
                data={coreData}
            />

            <Table
                title={t("vehicleDetailedView.technicalData.title")}
                Icon={<CoreDataIcon />}
                data={technicalData}
            />

            <Table
                title={t("vehicleDetailedView.range.title")}
                Icon={<RangeIcon />}
                data={rangeData}
                footNote={
                    <>
                        <span>
                            {t("vehicleDetailedView.range.footNote.note")}
                        </span>
                        <ul className="list-disc pl-4">
                            <li>
                                {t("vehicleDetailedView.range.footNote.bullet1")}
                            </li>
                            <li>
                                {t("vehicleDetailedView.range.footNote.bullet2")}
                            </li>
                        </ul>
                        <span>
                            {t("vehicleDetailedView.range.footNote.finalNote")}
                        </span>
                    </>
                }
            />

            <Table
                title={t("vehicleDetailedView.battery.title")}
                Icon={<CoreDataIcon />}
                data={batteryData}
            />

            <Table
                title={t("vehicleDetailedView.charging.title")}
                Icon={<ChargingIcon />}
                data={chargingData}
            />

            <Table
                title={t("vehicleDetailedView.dimensionsAndWeight.title")}
                Icon={<CoreDataIcon />}
                data={dimensionsAndWeightData}
            />

            <Table
                title={t("vehicleDetailedView.miscellaneous.title")}
                Icon={<MiscellaneousIcon />}
                data={miscellaneousData}
            />
        </>
    )


}