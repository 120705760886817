import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "../ModalWrapper";
import SimpleModalHeader from "../ModalWrapper/Headers";
import { EvDetails } from ".";
import { Spinner, SpinnerSize } from "../Spinner";
import { AlternativeEletricVehicleDetails } from "../../types";



export function EvDetailsModal({
    isOpen,
    vehicle,
    onCancel,
    content,
}: {
    isOpen: boolean;
    vehicle: AlternativeEletricVehicleDetails | null;
    onCancel: () => void;
    content: ReactElement;
}) {

    const { t } = useTranslation("fleetSimulation");

    const headerTitle = vehicle
        ? `${vehicle?.make} ${vehicle?.model}`
        : null;



    return (
        <ModalWrapper
            isOpen={isOpen}
            close={onCancel}
            className={"w-4/6"}>
            <>
                <SimpleModalHeader
                    onCancel={onCancel}
                    subTitle={t("alternative-ev-details.subtitle")}
                    title={headerTitle || ""}
                />
                {vehicle ? (
                    <EvDetails
                        vehicle={vehicle}
                        vehicleStats={content}
                    />
                ) : (
                    <div className="flex items-center justify-center p-20 text-Grey-dark">
                        <Spinner size={SpinnerSize.LARGE} />
                        <div className="ml-2">
                            Loading vehicle content...
                        </div>
                    </div>
                )}
            </>
        </ModalWrapper >
    );
}
