import { useTranslation } from "react-i18next";
import { MatchedVehiclesTable } from "./MatchedVehiclesTable";
import { VehicleInfoDropDown } from "./VehicleInfoDropDown";
import { useManualVehicleSelector } from "./useManualVehicleSelector";
import { DocumentNode } from "graphql";
import { Vehicle } from "../../../../@types/vehicle";
import { Button } from "../../misc/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../misc/Buttonv2/types";



export function ManualVehicleInput({
    vehicleData,
    onMutationSuccessful,
    mutation
}: {
    vehicleData: Vehicle | undefined,
    onMutationSuccessful: () => void,
    mutation: DocumentNode,
}) {


    const { t } = useTranslation("editVehicle");

    const {
        manualInputForm,
        brandsResponse,
        handleChange,
        modelsResponse,
        variantsResponse,
        matchedVehiclesResponse,
        initialModelId,
        setInitialModelId,
        saveManual
    } = useManualVehicleSelector({ vehicleData, onMutationSuccessful, mutation })

    const buttonDisabled = !manualInputForm.formState?.modelInfo?.modelId;


    return (
        <>
            <VehicleInfoDropDown
                className={"w-1/2 p-2"}
                label={t("brand")}
                placeholder={manualInputForm?.formState?.modelInfo?.brand}
                data={
                    brandsResponse
                        ? brandsResponse?.currentVehicleBrands?.map((b: string) => ({
                            key: b,
                            onClick: () => handleChange("brand", b),
                        }))
                        : []
                }
            />
            <VehicleInfoDropDown
                className={"w-1/2 p-2"}
                disabled={!manualInputForm?.formState?.modelInfo?.brand}
                label={t("model")}
                placeholder={manualInputForm?.formState?.modelInfo?.model}
                data={
                    modelsResponse
                        ? modelsResponse?.currentVehicleModels?.map((m: string) => ({
                            key: m,
                            onClick: () => handleChange("model", m),
                        }))
                        : []
                }
            />
            <VehicleInfoDropDown
                className={"w-1/2 p-2"}
                disabled={!manualInputForm?.formState?.modelInfo?.model}
                label={t("variant")}
                placeholder={manualInputForm?.formState?.modelInfo?.variant}
                data={
                    variantsResponse
                        ? variantsResponse?.currentVehicleVariants?.map((m: string) => ({
                            key: m,
                            onClick: () => handleChange("variant", m),
                        }))
                        : []
                }
            />
            

            <div className="mt-6">
                {matchedVehiclesResponse?.currentVehicles?.length > 0 &&
                    manualInputForm.formState?.modelInfo?.variant ? (
                    <MatchedVehiclesTable
                        initialModelId={initialModelId}
                        setInitialModelId={setInitialModelId}
                        manualInputForm={manualInputForm}
                        vehicles={matchedVehiclesResponse.currentVehicles}
                    />
                ) : null}
            </div>

            <div className="mt-14">
                <Button
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.LARGE}
                    // className={`rounded px-20 py-4 text-white ${buttonDisabled ? 'cursor-default bg-Grey-tint' : 'bg-Blueberry-dark-default cursor-pointer hover:bg-Blueberry-light-default'}`}
                    disabled={buttonDisabled}
                    onClick={saveManual}
                >
                    {t("vehicle-save")}
                </Button>
            </div>
        </>
    )


}