import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserData } from "../../../../hooks/state/useUserData";
import { DriverPaymentGatewayContext } from "../../contexts/DriverPaymentGatewayContext";
import { useAvailableByDefaultRoutes } from "./useAvailableByDefaultRoutes";
import { B2CProductTreeContext } from "../../contexts/B2CProductTreeContext";
import { ElectrifySubProducts } from "../../../../enums";


export function useOnboardingRedirects() {

    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useUserData();

    const { product } = useContext(B2CProductTreeContext);

    const { isProductPaid } = useContext(DriverPaymentGatewayContext);

    const {isCurrentRouteAvailableByDefault} = useAvailableByDefaultRoutes();


    useEffect(() => {

        const mainPathName = location.pathname.split('/')[1];
        const usableProduct = user?.accountInfo?.usableProduct;
        const isRoadMapCompleted = user?.accountInfo?.dongleRoadMapCompleted;

        const roadMapRequired = product === ElectrifySubProducts.DRIVER_DONGLE && !isRoadMapCompleted;

        if (roadMapRequired && mainPathName !== "dongle-welcome" && isProductPaid && !isCurrentRouteAvailableByDefault) {
            navigate("dongle-welcome");
        } else if (usableProduct === false && mainPathName !== "onboarding" && !roadMapRequired && product && !isCurrentRouteAvailableByDefault) {
            navigate("onboarding");
        } 
    }, [
        location?.pathname,
        user?.accountInfo?.usableProduct,
        user?.accountInfo?.dongleRoadMapCompleted,
        product,
    ])

}