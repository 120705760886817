import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";


import { useMemo, useState } from "react";

import { useLayout } from "../../../../../../hooks/state/useLayout";
import { ArrowButton } from "../../../../../../electrify_frontend_common/components/ArrowButton";
import { VehicleWithYearlyStats } from "../../../../../../@types/vehicle";
import { GET_VEHICLE_SELECTED_EV } from "../../../../../../services/graphql/fleet/queries/simulation";
import { EvDetailedViewModal } from "../../../../../../common/components/VehicleSimulationAndSelection/EvDetailedViewModal";
import { BrandInfo } from "../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { InfoSquare, InfoType } from "./components";
import { getImageServerWithFallback, replaceImgWithError } from "../../../../../../electrify_frontend_common/utils";
import { AlternativeEletricVehicleDetails } from "../../../../../../@types";



function CardFooter({
  selectedEv,
  onButtonClick,
  currencySymbol,
  localeCode
}: {
  selectedEv: AlternativeEletricVehicleDetails;
  onButtonClick: () => void;
  currencySymbol: string;
  localeCode: string;
}) {
  const { t } = useTranslation("driverOverview");


  return (
    <div className="flex justify-between items-end h-full">
      <div>
        <div className="text-xs text-Grey-default">
          {t("future-vehicle.list-price")}
        </div>
        <div className="font-extrabold text-Grey-dark">
          {selectedEv?.listPrice?.toLocaleString(localeCode)}{" "}
          <span className="text-xs">{currencySymbol}</span>
        </div>
      </div>
      <ArrowButton
        onClick={(e: any) => {
          e.stopPropagation();
          onButtonClick();
        }}
        className="bg-white "
        text={t("future-vehicle.more-info")}
      />
    </div>
  );
}

export function FutureVehicle({
  vehicle,
  currencySymbol,
  localeCode
}: {
  vehicle: VehicleWithYearlyStats;
  currencySymbol: string;
  localeCode: string;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("driverOverview");

  const [detailsOpen, setDetailsOpen] = useState(false);

  const { data } = useQuery<{ vehicleSelectedEv: { selectedEv: any } }>(
    GET_VEHICLE_SELECTED_EV,
    {
      variables: {
        vehicleId: vehicle?.id,
      },
    }
  );

  const fuelCostsComparison = useMemo(() => {
    const selectedEvCosts =
      data?.vehicleSelectedEv?.selectedEv?.yearlyFuelAndEnergyCosts;
    if (selectedEvCosts) {
      return Math.round(
        ((selectedEvCosts - vehicle.yearlyFuelAndEnergyCosts) /
          vehicle.yearlyFuelAndEnergyCosts) *
        100
      );
    }
    return 0;
  }, [
    vehicle.yearlyFuelAndEnergyCosts,
    data?.vehicleSelectedEv?.selectedEv?.yearlyFuelAndEnergyCosts,
  ]);

  const emissionsComparison = useMemo(() => {
    const selectedEvCosts =
      data?.vehicleSelectedEv?.selectedEv?.yearlyCo2Emission;
    if (selectedEvCosts) {
      return Math.round(
        ((selectedEvCosts - vehicle.yearlyCo2Emission) /
          vehicle.yearlyCo2Emission) *
        100
      );
    }
    return 0;
  }, [
    vehicle.yearlyCo2Emission,
    data?.vehicleSelectedEv?.selectedEv?.yearlyCo2Emission,
  ]);

  return (
    <>
      {detailsOpen ? (
        <EvDetailedViewModal
          isOpen={detailsOpen}
          onCancel={() => setDetailsOpen(false)}
          vehicleId={vehicle.id}
          evDetailQueryParams={data?.vehicleSelectedEv?.selectedEv?.evId}
        />
      ) : null}

      <div className="flex flex-col w-full h-full">
        <h1 className=" text-xl text-Black">{t("future-vehicle.title")}</h1>
        <div
          className="border border-Grey-tint bg-Grey-background rounded p-4 mt-5 flex h-full cursor-pointer hover:shadow"
          onClick={() => navigate("/ev-finder?change-vehicle")}
        >
          <div className="flex w-full h-full">
            <div className="flex flex-col w-3/4 h-full px-1 justify-between">
              <div className="flex h-2/12">
                <BrandInfo
                  vehicleBrand={data?.vehicleSelectedEv?.selectedEv?.make}
                  vehicleModel={
                    data?.vehicleSelectedEv?.selectedEv?.model
                  }
                />
              </div>
              <div className="flex w-full justify-center p-3">
                {data?.vehicleSelectedEv?.selectedEv?.evId ?
                  <img
                    src={`${getImageServerWithFallback()}/images/vehicle/ev/medium?evId=${data?.vehicleSelectedEv?.selectedEv?.evId
                      }`}
                    onError={(e) => replaceImgWithError(e, vehicle?.modelInfo)}
                  />
                  : null}

              </div>
              <div className="h-2/12">
                <CardFooter
                  selectedEv={data?.vehicleSelectedEv?.selectedEv}
                  onButtonClick={() => setDetailsOpen(true)}
                  currencySymbol={currencySymbol}
                  localeCode={localeCode}
                />
              </div>
            </div>
            <div className="w-1/4 flex flex-col h-full justify-between">
              <InfoSquare
                type={InfoType.ELECTRIFICATION}
                value={
                  data?.vehicleSelectedEv?.selectedEv?.electrificationFactor
                }
              />
              <InfoSquare
                type={InfoType.COSTS}
                value={data?.vehicleSelectedEv?.selectedEv?.yearlyFuelAndEnergyCosts.toLocaleString(
                  localeCode
                )}
                comparison={fuelCostsComparison}
                customLabel={currencySymbol}
              />
              <InfoSquare
                type={InfoType.EMISSIONS}
                value={data?.vehicleSelectedEv?.selectedEv?.yearlyCo2Emission.toLocaleString(
                  localeCode
                )}
                comparison={emissionsComparison}
                customLabel={"kg"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
