import { useState, useRef, ReactElement } from 'react';
import { useOutsideAlerter } from '../../hooks/useOutsideClickAlerter';

export default function Dropdown({
	placeholder,
	data,
	className,
	label,
	disabled,
	icon,
	buttonClassName,
	itemsClassName,
	loadUpwards,
}: {
	placeholder?: string | ReactElement | null;
	data: any[];
	className?: string;
	label?: string;
	disabled?: boolean;
	icon?: ReactElement;
	buttonClassName?: string;
	itemsClassName?: string;
	loadUpwards?: boolean;
}) {
	const [active, setActive] = useState(false);
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, () => {
		setActive(false);
	});

	return (
		<div ref={wrapperRef} className={'flex flex-col ' + className}>
			<label className="block text-h5">{label}</label>
			<button
				className={`dropdownAction focus:outline-none whitespace-nowrap trans flex w-full items-center space-x-2 p-3 overflow-hidden ${disabled ? 'cursor-default' : 'cursor-pointer'
					} ${icon ? 'justify-between' : 'justify-center'
					} ${buttonClassName}`}
				onClick={() =>
					!disabled
						? setActive(!active)
						: () => console.log('Disabled')
				}
				disabled={disabled}
			>
				<div className='overflow-x-hidden text-ellipsis'>
					{placeholder || <div></div>}
				</div>
				{icon ? (
					<div className={`${disabled ? 'opacity-60' : ''} ${active ? 'rotate-180 ' : ''} duration-300`}>
						{icon}
					</div>
				) : null}
			</button>
			{active ? (
				<div className="relative min-w-full">
					<div
						className={
							'dropdownAction absolute max-h-32 min-w-fit w-full mt-1 overflow-y-auto overflow-x-hidden shadow trans rounded origin-center z-40 bg-white left-1/2 transform -translate-x-1/2 whitespace-wrap ' +
							(active
								? 'opacity-100 scale-100 visible '
								: 'opacity-0 scale-75 hidden ') +
							(loadUpwards ? 'bottom-12' : '')
						}
					>
						{data.map((item: any, i: number) => {
							return (
								<Item
									key={i}
									active={item.key === placeholder}
									onClick={() => {
										item.onClick();
										setActive(false);
									}}
									itemsClassName={itemsClassName}
								>
									{item.key}
								</Item>
							);
						})}
					</div>
				</div>
			) : null}
		</div>
	);
}

function Item({ itemsClassName, active, children, onClick}: any) {
	return (
			<div
				className={`dropdownAction text-h5 cursor-pointer trans flex w-full px-3 py-2 hover:bg-secondary-100 ${active ? 'bg-secondary-100' : ''} ${itemsClassName}`}
				onClick={onClick}
			>
				{children}
			</div>
	);
}
