import { useTranslation } from "react-i18next";
import { ArrowButton } from "../../../../../../electrify_frontend_common/components/ArrowButton";
import { BrandInfo } from "../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { FavoriteVehicleCard, FavoriteVehicleMetric } from "../../../../../../electrify_frontend_common/components/VehicleCards/FavoriteVehicleCard";
import FavoriteButton from "../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/FavoriteButton";
import { FreeEvFinderContext } from "../FreeEvFinderContext";
import { useContext } from "react";
import { AlternativeVehicle } from "../../../../../../electrify_frontend_common/types";
import { useLayout } from "../../../../../../hooks/state/useLayout";



function FreeEvFinderFavoriteMetrics({ vehicle, localeCode }: { vehicle: Partial<AlternativeVehicle>, localeCode: string }) {


    const { t } = useTranslation("evFinder");

    return (
        <div>
            <div>
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.listPrice")}
                    value={vehicle.listPrice || "0"}
                    unit={"€"}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.availabilityStatus")}
                    value={vehicle.availableNow ? "Available" : "Coming soon"}
                />
            </div>
            <div className="pt-4">
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.loadWeight")}
                    value={vehicle.loadWeight || "0"}
                    unit={"kg"}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.loadVolume")}
                    value={vehicle.loadVolume || "0"}
                    unit={"l"}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.countOfSeats")}
                    value={vehicle.seatsCount || "0"}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.drivetrainPropulsion")}
                    value={vehicle.propulsion || "0"}
                />
            </div>
            <div className="pt-4">
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.towingCapacity")}
                    value={vehicle.towHitchPossible ? "Yes" : "No"}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.totalTorque")}
                    value={vehicle.totalTorque || "0"}
                    unit={"Nm"}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.expectedRealRange")}
                    value={vehicle.range || "0"}
                    unit="km"
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.vehicleConsumption")}
                    value={vehicle.energyConsumption || "0"}
                    unit="kWh"
                />
            </div>
            <div className="pt-4">
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.acChargeSpeed")}
                    value={vehicle.acChargeSpeed || "N.A"}
                    unit={vehicle.acChargeSpeed ? "km / h" : ""}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.acChargePower")}
                    value={vehicle.acChargePower || "N.A"}
                    unit={vehicle.acChargePower ? "kWh" : ""}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.dcChargeSpeed")}
                    value={vehicle.dcChargeSpeed || "N.A"}
                    unit={vehicle.dcChargeSpeed ? "km / h" : ""}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.dcChargePower")}
                    value={vehicle.dcChargePower || "N.A"}
                    unit={vehicle.dcChargePower ? "kWh" : ""}
                />
            </div>
        </div>
    )

}



export function FreeEvFinderFavoriteVehicleCard({ vehicle, onClick }: { vehicle: Partial<AlternativeVehicle>, onClick: () => void }) {

    const { t } = useTranslation("evFinder")

    const { favoriteVehiclesList, toggleFavorite } = useContext(FreeEvFinderContext);
    const {localeCode} = useLayout();


    return (
        <FavoriteVehicleCard
            vehicle={vehicle}
            moreInfoClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
            cardHeader={<div className="flex w-full justify-between p-3">
                <BrandInfo
                    vehicleBrand={vehicle?.make}
                    vehicleModel={vehicle?.model}
                />
            </div>}
            additionalActions={<div className="flex w-full justify-between px-3">
                <FavoriteButton
                    alternativeVehicle={vehicle}
                    addToFavorites={() => toggleFavorite(vehicle.evId || "")}
                    removeFromFavorites={() => toggleFavorite(vehicle.evId || "")}
                    isFavorited={(() => {
                        const exists = !!favoriteVehiclesList?.map((ev) => ev.evId).includes(vehicle.evId);
                        return exists;
                    })()}
                />
                <ArrowButton
                    text={t("vehicleCard.moreInfo")}
                    onClick={(e) => {
                        e?.stopPropagation();
                        onClick();
                    }}
                />
            </div>}

            cardFooter={<FreeEvFinderFavoriteMetrics localeCode={localeCode} vehicle={vehicle} />}
        />
    )


}