

export function CheckIcon({color= "#22C55E", className} : {color?: string, className?: string}) {

    return (

        <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 13L9 17L19 7" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>

    )


}