import { AlternativeEletricVehicleDetails } from "../../types";
import { getImageServerWithFallback } from "../../utils";
import { CarIconSelector } from "../CarIconSelector";


export function EvDetails({
    vehicle,
    vehicleStats
}: {
    vehicle: AlternativeEletricVehicleDetails;
    vehicleStats: any;
}) {

    const replaceImgWithError = (e: any) => {
        e.target.onerror = null;
        e.target.src = CarIconSelector(
            vehicle?.bodyStyle,
            vehicle?.category
        );
    };

    
    return (
        <>
            <div className="p-6 pt-16 px-28  overflow-y-auto">
                <img
                    className="w-full"
                    src={
                        getImageServerWithFallback() +
                        "/images/vehicle/ev/large" +
                        "?evId=" +
                        vehicle.evId
                    }
                    onError={replaceImgWithError}
                    alt="electric-vehicle-details"
                />
                {vehicleStats}
            </div>
        </>
    )

}

