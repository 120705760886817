import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Payment } from "./Payment";
import { CloseIcon } from "../../../../../../../electrify_frontend_common/icons/CloseIcon";
import { useContext } from "react";
import { DriverPaymentGatewayContext } from "../../../../../contexts/DriverPaymentGatewayContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

export function PaymentForm({ clientSecret }: { clientSecret: string }) {

    const { closePaymentModal } = useContext(DriverPaymentGatewayContext);


    return (
        <div className="flex flex-col h-full overflow-y-auto shadow-md">
            <div className="flex w-full justify-end pt-8 px-8"><CloseIcon className="cursor-pointer" onClick={closePaymentModal} /></div>
            <div className="flex flex-col w-full h-full justify-center items-center">
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <Payment />
                </Elements>
            </div>
        </div>
    );
}
