
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import Dropdown from "../../../../components/Dropdown";
import { ExpandIcon } from "../../../../icons/ExpandIcon";



function CollapsedSelectionSorter({
  sortBy,
  handleOnChangeSorter,
  optionsMap,
}: {
  sortBy: { label: string; key: string; order: "ASC" | "DESC" };
  handleOnChangeSorter: (v: any) => void;
  optionsMap: {
    label: string;
    key: string;
    order: "ASC" | "DESC";
  }[];
}) {

  const { t } = useTranslation("evFinder");

  const [collapsed, setCollapsed] = useState(true);


  return (
    <>
      <div onClick={() => setCollapsed(!collapsed)} className="flex justify-between items-center text-white py-2">
        <span className="text-xs font-bold">{t("sorter.title")}</span>
        {collapsed ? <ExpandIcon /> : <div className="rotate-180"><ExpandIcon /></div>}
      </div>
      <div className={`${collapsed ? 'hidden' : 'block'}`}>
        <RegularSelectionSorter sortBy={sortBy} handleOnChangeSorter={handleOnChangeSorter} optionsMap={optionsMap} />
      </div>
    </>
  )


}


function RegularSelectionSorter({
  sortBy,
  handleOnChangeSorter,
  optionsMap,
}: {
  sortBy: { label: string; key: string; order: "ASC" | "DESC" };
  handleOnChangeSorter: (v: any) => void;
  optionsMap: {
    label: string;
    key: string;
    order: "ASC" | "DESC";
  }[];
}) {

  const { t } = useTranslation("evFinder");

  return (
    <>

      <div className="flex w-auto text-xs h-10 items-center my-4 sm:py-0">
        <div
          className={`border flex border-Grey-tint bg-white solid rounded h-full text-xs items-center w-full sm:1/2 md:w-52`}
        >
          <Dropdown
            className="w-full h-full bg-Black-background"
            data={optionsMap.map((v) => ({
              key: t(`sorter.options.${v.label}`),
              onClick: () => handleOnChangeSorter(v),
            }))}
            placeholder={t(`sorter.options.${sortBy.label}`)}
            // placeholder={t("sorter.alternatives-selection")}
            // value={sortBy}
            // onChange={(e: any) => handleOnChangeSorter(e.target.value)}
            icon={<ExpandIcon color="black" />}
          />
        </div>
      </div>
    </>
  );
}


export function SelectionSorter({
  sortBy,
  handleOnChangeSorter,
  optionsMap,
}: {
  sortBy: { label: string; key: string; order: "ASC" | "DESC" };
  handleOnChangeSorter: (v: any) => void;
  optionsMap: {
    label: string;
    key: string;
    order: "ASC" | "DESC";
  }[];
}) {

  const { t } = useTranslation("evFinder");

  const { widthIsLessThan1024 } = useScreenSize();


  return (
    <>
      {widthIsLessThan1024 ? <CollapsedSelectionSorter sortBy={sortBy} handleOnChangeSorter={handleOnChangeSorter} optionsMap={optionsMap} /> :
        <div className="flex items-center ">
          <div className="text-Grey-dark text-xs font-bold mr-2">{t("sorter.label")}</div>
          <RegularSelectionSorter sortBy={sortBy} handleOnChangeSorter={handleOnChangeSorter} optionsMap={optionsMap} />
        </div>}
    </>
  )

}
