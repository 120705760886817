import { ReactElement, useMemo } from "react";
import { OfferBanner } from "../BaseVehicleCard/components/OfferBanner";
import { VehicleImageWithFallback } from "../../VehicleImageWithFallback";
import { AlternativeEletricVehicleDetails } from "../../../types";


export function FavoriteVehicleMetric({ label, value, unit, isSelected, localeCode }: { label: string, value: string | number, unit?: string, isSelected?: boolean, localeCode: string }) {


    return (
        <div className={`flex items-center w-full justify-between py-1 ${isSelected ? 'text-white' : ''}`}>
            <div className="text-xs  w-1/2">{label}</div>
            <div className="text-xs ">
                <span className="font-bold">{value.toLocaleString(localeCode)}</span> {unit}
            </div>
        </div>
    )

}


export function FavoriteVehicleCard({
    cardHeader,
    vehicle,
    cardFooter,
    footerBgColor = "bg-Grey-background",
    button,
    additionalActions,
    moreInfoClick
}: {
    cardHeader: ReactElement | null,
    vehicle: Partial<AlternativeEletricVehicleDetails>,
    cardFooter: ReactElement,
    footerBgColor?: string,
    button?: ReactElement,
    additionalActions: ReactElement,
    moreInfoClick?: (e: any) => void
}) {

    const dimensions = useMemo(() => {
        return {
            cardHeaderHeight: 'h-[50px]',
            bannerTopOffset: 'top-[212px]',
            additionalActionsTopOffset: 'top-[180px]'
        }
    }, [button]);


    return (
        <div className={` bg-white border border-Grey-tint rounded overflow-hidden relative w-[280px] `}>
            <div className={dimensions.cardHeaderHeight}>
                {cardHeader}
            </div>
            <div className={`${!cardFooter ? 'h-[192px]' : 'h-[170px]'}`}>
                <div className="flex flex-col flex-1 justify-center bg-white items-center h-full w-full">
                    <div className={`p-6 ${!cardFooter ? ' pb-[70px]' : 'pb-12'}`}>
                        <VehicleImageWithFallback
                            onClick={moreInfoClick}
                            className="max-h-32"
                            vehicle={vehicle}
                        />
                      
                    </div>
                </div>
            </div>

            <div className={`h-full w-full flex flex-col ${footerBgColor} justify-between border-t border-t-Grey-tint p-6 pt-8 `}>
                <div className="flex w-full justify-center">
                    {button}
                </div>
                <div>
                    {cardFooter}
                </div>

            </div>


            <div className={`h-8 max-h-8 overflow-hidden flex w-full absolute ${dimensions.additionalActionsTopOffset} left-0`}>{additionalActions}</div>


            <OfferBanner offerToDisplay={vehicle?.offerToDisplay} />

        </div>
    );


}