import { UserType } from "../../../../../enums";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { CREATE_BUSINESS_ACCOUNT, CREATE_PERSONAL_ACCOUNT } from "../../../../../services/graphql/public";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Button } from "../../../../components/misc/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../components/misc/Buttonv2/types";
import {  PasswordInput, RegistrationCheckBox, RegistrationFormInput } from "./utils";
import { Spinner, SpinnerSize } from "../../../../../electrify_frontend_common/components/Spinner";



export function SignupForm({ accountType }: { accountType: UserType }) {

    const { t, i18n } = useTranslation("registerForm");
    const currentLanguage = i18n.language;
    const isPersonalAccount = accountType === UserType.DRIVER;

    const navigate = useNavigate();
    const mutation = isPersonalAccount ? CREATE_PERSONAL_ACCOUNT : CREATE_BUSINESS_ACCOUNT;

    const [createUser, { loading }] = useMutation(mutation, {
        onCompleted: async (data) => {
            console.log("COMPLETED", data);
            navigate(`/sign-up/verify-email`);
        },
        onError: () => {
            console.log("ERRROR");
        },
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            companyName: '',
            termsAndConditions: false,
            marketing: false
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required('first-name-required'),
            lastName: Yup.string()
                .required('last-name-required'),
            email: Yup.string()
                .email('email-format')
                .required('email-required'),
            password: Yup.string()
                .required('password-required')
                .min(8, 'password-format')
                .matches(/[a-z]/, 'password-format')
                .matches(/[A-Z]/, 'password-format')
                .matches(/[0-9]/, 'password-format')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'password-format'),
            companyName: isPersonalAccount ?
                Yup.string() :
                Yup.string()
                    .required('company-name-required'),
            termsAndConditions: Yup.boolean()
                .oneOf([true], 'terms-conditions-required'),
            marketing: Yup.boolean()
        }),
        onSubmit: (value) => {

            createUser({
                variables: {
                    newUser: {
                        username: value.email,
                        language: currentLanguage,
                        product: accountType,
                        ...value
                    }
                }
            })
        }
    })



    if (loading) return <div className="flex flex-col h-full w-full items-center justify-center">
        <Spinner
            size={SpinnerSize.LARGE}
        />
        <div className=" text-Grey-shade">Registering user...</div>
    </div>;


    return (
        <div className="pb-20">
            <div className="mb-10">
                <div onClick={()=> navigate('/')} className="text-Blueberry-dark-default hover:underline cursor-pointer mb-2">Already have and account? Go to Login</div>
                <h1 className="text-3xl text-Black-deafult">{isPersonalAccount ? t("title-personal") : t("title")}</h1>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <RegistrationFormInput
                    type="text"
                    name="firstName"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    placeholder={t("first-name")}
                    value={formik.values.firstName}
                    label={t("first-name")}
                    touched={formik?.touched?.firstName}
                    error={formik?.errors?.firstName ? t(`${formik?.errors?.firstName}`) : undefined}
                />

                <RegistrationFormInput
                    type="text"
                    name="lastName"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    placeholder={t("last-name")}
                    label={t("last-name")}
                    touched={formik?.touched?.lastName}
                    error={formik?.errors?.lastName ? t(`${formik?.errors?.lastName}`) : undefined}
                />

                <RegistrationFormInput
                    type="email"
                    name="email"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder={t("email")}
                    label={t("email")}
                    touched={formik?.touched?.email}
                    error={formik?.errors?.email ? t(`${formik?.errors?.email}`) : undefined}
                />

                {isPersonalAccount ? null : (
                    <RegistrationFormInput
                        type="text"
                        name="companyName"
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        value={formik.values.companyName}
                        placeholder={t("company-name")}
                        label={t("company-name")}
                        touched={formik?.touched?.companyName}
                        error={formik?.errors?.companyName ? t(`${formik?.errors?.companyName}`) : undefined}
                    />
                )}

                <PasswordInput
                    name="password"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.password}
                    placeholder={t("password")}
                    label={t("password")}
                    touched={formik?.touched?.password}
                    error={formik?.errors?.password ? t(`${formik?.errors?.password}`) : undefined}
                />


                <RegistrationCheckBox
                    name="termsAndConditions"
                    handleChange={formik.handleChange}
                    checked={formik.values.termsAndConditions}
                    error={formik?.errors?.termsAndConditions ? t(`${formik?.errors?.termsAndConditions}`) : undefined}
                    touched={formik?.touched?.termsAndConditions}
                    label={<label className="text-base text-Grey-shade">
                        <span>{t("terms-conditions-text-start")} </span>
                        <a
                            className="text-Blueberry-dark-default"
                            href={t("terms-of-use-url")}
                        >
                            {t("terms-of-use-name")}
                        </a>
                        <span> & </span>
                        <a
                            className="text-Blueberry-dark-default"
                            href={t("data-privacy-url")}
                        >
                            {t("data-privacy-name")}
                        </a>
                        <span> {t("terms-conditions-text-end")}</span>
                    </label>}
                />

                <RegistrationCheckBox
                    name="marketing"
                    handleChange={formik.handleChange}
                    checked={formik.values.marketing}
                    error={formik?.errors?.marketing ? t(`${formik?.errors?.marketing}`) : undefined}
                    touched={formik?.touched?.marketing}
                    label={<label className="text-base text-Grey-shade">
                        {t("marketing")}
                    </label>}
                />

                <Button
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.LARGE}
                    type="submit"
                    onClick={() => null}
                    className="flex w-full justify-center"
                >
                    {t("register")}
                </Button>


            </form>

        </div >
    )


}