import { useTranslation } from "react-i18next";
import ChartButtonHeader from "../../../../common/components/Charts/ChartButtonHeader";
import Dropdown from "../../../../electrify_frontend_common/components/Dropdown";
import { ExpandIcon } from "../../../../electrify_frontend_common/icons/ExpandIcon";



export function EvFinderControlPanel({
    alternativesCount,
    favoritesCount,
    sortBy,
    setSortBy,
    favoritesOnly,
    setFavoritesOnly
}: {
    alternativesCount: number,
    favoritesCount: number,
    sortBy: string,
    setSortBy: (value: string) => void,
    favoritesOnly: boolean,
    setFavoritesOnly: (value: boolean) => void
}) {

    const { t } = useTranslation("evFinder");


    const alternativesSortMap = {
        "highScore": t(`sorter.options.chargilizeRecommendation`),
        "highElectrificationFactor": t(`sorter.options.highestEvScore`),
        "lowPrice": t(`sorter.options.listingPrice`),
        "rangePerPrice": t(`sorter.options.rangePerPrice`),
        "realRange": t(`sorter.options.realRange`),
        "lowConsumptionCosts": t(`sorter.options.consumptionCosts`),
        "lowCo2Emission": t(`sorter.options.co2Emissions`),
    };

    return (
        <div className="flex py-10 w-full items-center justify-between">
            <ChartButtonHeader
                button1={{
                    onClick: () => {
                        setFavoritesOnly(false);
                    },
                    label: t("headerButtons.allVehicles"),
                    vehiclesCount: alternativesCount,
                }}
                button2={{
                    onClick: () => {
                        setFavoritesOnly(true);
                    },
                    label: t("headerButtons.favoritesOnly"),
                    vehiclesCount: favoritesCount,
                }}
                checkProp={!favoritesOnly}
            />

            <div className="ml-4">
                <SelectionSorter
                    sortBy={sortBy}
                    handleOnChangeSorter={(value: any) => setSortBy(value)}
                    optionsMap={alternativesSortMap}
                />
            </div>

        </div>
    )
}



export function SelectionSorter({
    sortBy,
    handleOnChangeSorter,
    optionsMap,
  }: {
    sortBy: string;
    handleOnChangeSorter: (v: any) => void;
    optionsMap: any;
  }) {
    const { t } = useTranslation("evFinder");
  
    return (
      <div className="flex w-auto text-xs h-10 items-center">
        <span className=" text-Grey-dark font-bold mr-2">
          {t("sorter.label")}
        </span>
        <div
          className={`border flex border-Grey-tint bg-white solid rounded h-full text-xs items-center w-52`}
        >
          <Dropdown
            className="w-full h-full"
            data={Object.entries(optionsMap).map(([k, v]) => ({
              key: v,
              onClick: () => handleOnChangeSorter(k)
            }))}
            placeholder={optionsMap[sortBy]}
            // placeholder={t("alternatives-selection")}
            // value={sortBy}
            // onChange={(e: any) => handleOnChangeSorter(e.target.value)}
            icon={<ExpandIcon color="black"/>}
          />
        </div>
      </div>
    );
  }
  