
import { ReactComponent as Consumption } from "../../../assets/consumption.svg";
import { ReactComponent as Price } from "../../../assets/price.svg";
import { ReactComponent as Range } from "../../../assets/range.svg";
import { useTranslation } from "react-i18next";
import { VehicleCard } from "../../VehicleCards/BaseVehicleCard";
import { BrandInfo } from "../../VehicleCards/BaseVehicleCard/components/BrandInfo";
import { ArrowButton } from "../../ArrowButton";
import { Metric } from "../../VehicleCards/BaseVehicleCard/components/Metric";
import FavoriteButton from "../../VehicleCards/BaseVehicleCard/FavoriteButton";
import { AlternativeVehicle } from "../../../types";


export function FreeEvFinderVehicleCard({
    vehicle,
    onClick,
    favoriteVehiclesList,
    toggleFavorite,
    localeCode
}: {
    vehicle: Partial<AlternativeVehicle>,
    onClick: () => void,
    favoriteVehiclesList?: Partial<AlternativeVehicle>[],
    toggleFavorite: (evId: string) => void,
    localeCode: string
}) {

    const { t } = useTranslation("evFinder")


    return (
        <VehicleCard
            vehicle={vehicle}
            moreInfoClick={(e) => {
                e?.stopPropagation();
                onClick();
            }}
            cardHeader={<div className="flex w-full justify-between p-3">
                <BrandInfo
                    vehicleBrand={vehicle?.make}
                    vehicleModel={vehicle?.model}
                />
            </div>}
            additionalActions={<div className="flex w-full justify-between items-center px-3">

                <FavoriteButton
                    alternativeVehicle={vehicle}
                    addToFavorites={() => toggleFavorite(vehicle.evId || "")}
                    removeFromFavorites={() => toggleFavorite(vehicle.evId || "")}
                    isFavorited={(() => {
                        const exists = !!favoriteVehiclesList?.map((ev) => ev.evId).includes(vehicle.evId);
                        return exists;
                    })()}
                />
                <ArrowButton
                    text={t("vehicleCard.moreInfo")}
                    onClick={(e) => {
                        e?.stopPropagation();
                        onClick();
                    }}
                />

            </div>}

            cardFooter={<>
                <Metric
                    icon={<Price className="w-4" />}
                    value={vehicle.listPrice || 0}
                    unit={"€"}
                    tooltipTitle={t("vehicleCard.tooltips.listPrice")}
                    localeCode={localeCode}
                />
                <Metric
                    icon={<Range className="w-4" />}
                    value={vehicle.range || 0}
                    unit={"km"}
                    tooltipTitle={t("vehicleCard.tooltips.range")}
                    localeCode={localeCode}
                />
                <Metric
                    icon={<Consumption />}
                    value={vehicle.energyConsumption || 0}
                    unit={"kWh / 100 km"}
                    tooltipTitle={t("vehicleCard.tooltips.consumption")}
                    localeCode={localeCode}
                />
            </>}
        />
    )


}