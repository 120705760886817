
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { ButtonSize, ButtonVariant } from "../../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { Range } from "../../../../../../../../../electrify_frontend_common/icons/Range";
import { Consumption } from "../../../../../../../../../electrify_frontend_common/icons/Consumption";
import { Price } from "../../../../../../../../../electrify_frontend_common/icons/Price";
import { Button } from "../../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { VehicleCard } from "../../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard";
import { BrandInfo } from "../../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { Metric } from "../../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/Metric";
import { ArrowButton } from "../../../../../../../../../electrify_frontend_common/components/ArrowButton";
import { AlternativeVehicle } from "../../../../../../../../../electrify_frontend_common/types";
import { useLayout } from "../../../../../../../../../hooks/state/useLayout";




export function VehicleSelectionCard({
    vehicle,
    onSelect,
    selected,
    hideFooter = false,
    onMoreInfoClick
}: {
    vehicle: Partial<AlternativeVehicle>,
    onSelect?: () => void,
    selected: boolean,
    hideFooter?: boolean,
    onMoreInfoClick: () => void
}) {

    const { t } = useTranslation("evFinder");

    const { localeCode } = useLayout();

    const cardColor = {
        backgroundColor: selected ? "bg-Grey-background" : "bg-Blueberry-dark-default",
        smallFont: "text-Grey-dark",
        bigFont: "text-black-default",
        buttonVariant: ButtonVariant.SECONDRY,
        icons: {
            range: <Range color={selected ? "white" : undefined} />,
            consumption: <Consumption color={selected ? "white" : undefined} />,
            price: <Price className="w-4" color={selected ? "white" : undefined} />,
        },
    };


    const button = useMemo(() => {
        if (!onSelect) return <></>
        return selected ?
            <div className="p-2 px-3 bg-white bg-opacity-20 text-sm rounded text-white">{t("vehicleCard.button.vehicleSelected")}</div>
            :
            <Button
                variant={cardColor.buttonVariant}
                size={ButtonSize.SMALL}
                onClick={(e) => {
                    e?.stopPropagation();
                    onSelect();
                }}
                className="flex w-full justify-center"
            >
                {t("vehicleCard.button.selectVehicle")}
            </Button>

    }, [onSelect, selected])

    return (

        <VehicleCard
            vehicle={vehicle}
            moreInfoClick={(e) => {
                if (!selected) {
                    e.stopPropagation();
                    onMoreInfoClick();
                }
                return;
            }}
            cardHeader={<div className="p-3">
                <BrandInfo
                    vehicleBrand={vehicle?.make}
                    vehicleModel={vehicle?.model}
                />
            </div>}
            additionalActions={<div className="flex w-full px-3 items-center justify-end">
                <ArrowButton
                    text={t("vehicleCard.moreInfo")}
                    onClick={(e) => {
                        e.stopPropagation();
                        onMoreInfoClick();
                    }}
                />
            </div>}
            isActive={selected}
            cardFooter={hideFooter ? undefined : <div>
                <Metric
                    localeCode={localeCode}
                    icon={cardColor.icons.price}
                    value={vehicle.listPrice || 0}
                    unit={"€"}
                    tooltipTitle={t("vehicleCard.tooltips.listPrice")}
                />

                <Metric
                    localeCode={localeCode}
                    icon={cardColor.icons.range}
                    value={vehicle.range || 0}
                    unit={"km"}
                    tooltipTitle={t("vehicleCard.tooltips.range")}
                />
                <Metric
                    localeCode={localeCode}
                    icon={cardColor.icons.consumption}
                    value={vehicle.energyConsumption || 0}
                    unit={"kWh / 100 km"}
                    tooltipTitle={t("vehicleCard.tooltips.consumption")}
                />
            </div>}
            button={button}
        />
    )


}