import { useTranslation } from "react-i18next";
import { Logo } from "../../../../../../../../common/components/LayoutWrapper/Logo";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import { ImageLayout, IncludedInProductLabel, ProductInformationLayout } from "./components";
import uploadImgMain from "../../../../../../../../assets/driver_payment/upload_1.webp";
import appLayoutImg from "../../../../../../../../assets/driver_payment/app_layout.webp";
import uploadImg from "../../../../../../../../assets/driver_payment/upload_2.webp";



export function DriverDataUploadProductInfo({ price, currencySymbol }: { price: number, currencySymbol: string }) {

    const { t } = useTranslation("driverPaymentSystem");

    return (
        <div className="text-Grey-dark flex flex-col h-full">
            <ProductInformationLayout
                title={t("products.driverDataUpload.name")}
                description={t("products.driverDataUpload.description")}
                price={price}
                currencySymbol={currencySymbol}
                checkList={<>
                    <IncludedInProductLabel label={t("products.driverDataUpload.checklist.point1")} />
                    <IncludedInProductLabel label={t("products.driverDataUpload.checklist.point2")} />
                </>}
            />
            <ImageLayout imageMain={<img src={uploadImgMain} />} image1={<img className="w-14" src={uploadImg} />} image2={<img src={appLayoutImg} />} />
        </div>


    )


}