import { useQuery } from "@apollo/client";
import { ReactElement, useState } from "react";
import { ReactComponent as ElectrificationFactorIcon } from "../../../../assets/ev_details/electrification-factor.svg";
import { ReactComponent as RealRangeIcon } from "../../../../assets/ev_details/real-range.svg";
import { ReactComponent as EnergyCostIcon } from "../../../../assets/ev_details/energy-cost.svg";
import { useTranslation } from "react-i18next";
import { AlternativeEletricVehicleDetails } from "../../../../@types";
import { GET_ALTERNATIVE_EV_DETAILS } from "../../../../services/graphql/fleet/queries/simulation";
import { useLayout } from "../../../../hooks/state/useLayout";
import { EvDetailsModal } from "../../../../electrify_frontend_common/components/EvDetails/EvDetailsModal";
import { MetricsDisplay, Table } from "../../../../electrify_frontend_common/components/EvDetails/components";
import { VehicleDetailsTable } from "../../../../electrify_frontend_common/components/EvDetails/VehicleDetailsTable";



export function EvDetailedViewModal({
  isOpen,
  vehicleId,
  evDetailQueryParams,
  onCancel,
}: {
  isOpen: boolean;
  vehicleId: string;
  evDetailQueryParams: string | null,
  onCancel: () => void;
}) {
  const { t } = useTranslation("evFinder");
  const { localeCode, currencySymbol } = useLayout();

  const [vehicleData, setVehicleData] =
    useState<AlternativeEletricVehicleDetails | null>(null);

  const mainStats = vehicleData
    ? {
      electrificationFactor:
        vehicleData?.electrificationFactor?.toLocaleString(localeCode),
      realRange:
        vehicleData?.evModelData?.range?.expectedRealRangeAverage?.toLocaleString(
          localeCode
        ),
      energyCost:
        vehicleData?.yearlyFuelAndEnergyCosts?.toLocaleString(localeCode),
      listPrice: vehicleData?.listPrice?.toLocaleString(localeCode),
    }
    : null;

  useQuery(GET_ALTERNATIVE_EV_DETAILS, {
    variables: {
      vehicleId: vehicleId,
      evId: evDetailQueryParams,
    },
    onCompleted: (data) => {
      setVehicleData(data.alternativeEvDetails);
    },
  });



  return (
    <EvDetailsModal
      isOpen={isOpen}
      vehicle={vehicleData}
      onCancel={onCancel}
      content={
        <>
           <div className="flex justify-between mt-12">
              <MetricsDisplay
                Icon={<ElectrificationFactorIcon />}
                property={t(
                  "vehicleDetailedView.mainStats.electrificationFactor"
                )}
                value={mainStats?.electrificationFactor}
                units={"%"}
              />
              <MetricsDisplay
                Icon={<RealRangeIcon />}
                property={t("vehicleDetailedView.mainStats.realRange")}
                value={mainStats?.realRange}
                units={"km"}
              />
              <MetricsDisplay
                Icon={<EnergyCostIcon />}
                property={t("vehicleDetailedView.mainStats.energyCost")}
                value={mainStats?.energyCost}
                units={`${currencySymbol} / year`}
              />
              <MetricsDisplay
                property={t("vehicleDetailedView.mainStats.listPrice")}
                value={mainStats?.listPrice}
                units={currencySymbol}
              />
            </div>

            <VehicleDetailsTable vehicleData={vehicleData} />
        </>
      }
    />
  );
}
