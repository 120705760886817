import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery as useRestQuery } from 'react-query';
import { agreeWithPayment, checkDriverVehicleSubscription } from '../../../services/rest/paymentProcess';
import { SubscriptionState } from '../../../enums';
import { useUserData } from '../../../hooks/state/useUserData';
import { Payments } from '../Electrify/pages/Payments';
import { PaymentCompleted } from '../Electrify/pages/Payments/Feedback';
import { B2CProductTreeContext } from './B2CProductTreeContext';


type VehicleSubscription = {
	product: any,
	state: SubscriptionState,
	clientSecret: string | null
}


export const DriverPaymentGatewayContext = createContext<{
	isProductPaid: boolean;
	isPaymentModalOpen: boolean;
	closePaymentModal: () => void;
	openPaymentModal: () => void;
	clientSecret: string | null;
	openPaymentFeedback: any;
	refreshVehicleSubscriptionState: any;
	exitPayment: any;
	goBackToPayment: any;
	agreedToProceed: boolean;
	agreeWithPayment: () => void;
}>({
	isProductPaid: false,
	isPaymentModalOpen: false,
	closePaymentModal: () => null,
	openPaymentModal: () => null,
	openPaymentFeedback: null,
	clientSecret: null,
	refreshVehicleSubscriptionState: null,
	exitPayment: null,
	goBackToPayment: null,
	agreedToProceed: false,
	agreeWithPayment: () => null
});

export const DriverPaymentGatewayContextProvider = ({
	children,
}: {
	children: ReactElement;
}) => {

	const { user } = useUserData();

	const { refetchUserSubscription } = useContext(B2CProductTreeContext);

	const location = useLocation();

	const [isPaymentModalOpen, setIsPaymentModalOpen] =
		useState<boolean>(false);

	const [isPaymentFeedbackOpen, setIsPaymentFeedbackOpen] =
		useState<boolean>(false);

	const [clientSecret, setClientSecret] = useState<string | null>(null);

	const [isProductPaid, setIsProductPaid] = useState(false);

	const [agreedToProceed, setAgreedToProceed] = useState(false);

	const agreeWithPaymentMutation = useMutation(agreeWithPayment, {
		onSuccess: () => {
			setAgreedToProceed(true)
		}
	});

	const closePaymentModal = () => {
		setIsPaymentModalOpen(false)
	};
	const openPaymentModal = () => {
		setIsPaymentModalOpen(true)
	};

	const {
		isLoading: isLoadingPaidVehicles,
		refetch: refreshVehicleSubscriptionState,
	} = useRestQuery<{
		product: any,
		state: SubscriptionState,
		clientSecret: string | null
	}>(['tariff', location.pathname, user?.accountInfo?.preSelectedProduct], checkDriverVehicleSubscription, {
		onSuccess: vehicleSubscription => {
			managePaymentState(vehicleSubscription);
		},
	});


	useEffect(() => {

		if (user?.accountInfo?.preSelectedProduct && !isProductPaid) {
			openPaymentModal();
		} else if (user?.accountInfo?.preSelectedProduct && isProductPaid) {
			closePaymentModal();
			setIsPaymentFeedbackOpen(false);
		} else if (!user?.accountInfo?.preSelectedProduct) {
			closePaymentModal();
			setIsPaymentFeedbackOpen(false);
		}

	}, [isProductPaid, user?.accountInfo?.preSelectedProduct])


	useEffect(() => {
		if (!isPaymentModalOpen) setAgreedToProceed(false)
	}, [isPaymentModalOpen])



	const managePaymentState = (
		vehicleSubscription: VehicleSubscription | undefined,
	) => {
		if (
			!vehicleSubscription ||
			vehicleSubscription.state ===
			SubscriptionState.NON_EXISTANT
		) {
			setIsProductPaid(false);
		} else if (
			!vehicleSubscription ||
			vehicleSubscription.state ===
			SubscriptionState.PAYMENT_FAILED
		) {
			setIsProductPaid(false);
			setClientSecret(vehicleSubscription?.clientSecret || null);
			setIsPaymentFeedbackOpen(true);
		} else {
			setIsProductPaid(true);
			refetchUserSubscription();
		}
	}


	const exitPayment = () => {
		setClientSecret(null);
		refreshVehicleSubscriptionState();
	};

	const openPaymentFeedback = (clientSecret: string) => {
		setClientSecret(clientSecret);
		setIsPaymentFeedbackOpen(true);
	};

	const goBackToPayment = () => {
		setIsPaymentFeedbackOpen(false);
	};

	const displayPaymentProcess = isPaymentModalOpen && !isLoadingPaidVehicles;
	const displayPaymentFeedback = isPaymentFeedbackOpen && clientSecret && !isLoadingPaidVehicles;

	return (
		<DriverPaymentGatewayContext.Provider
			value={{
				isProductPaid,
				isPaymentModalOpen,
				closePaymentModal,
				openPaymentModal,
				clientSecret,
				refreshVehicleSubscriptionState,
				exitPayment,
				openPaymentFeedback,
				goBackToPayment,
				agreedToProceed,
				agreeWithPayment: agreeWithPaymentMutation.mutate
			}}
		>
			{displayPaymentProcess ? <Payments /> : null}
			{displayPaymentFeedback ? <PaymentCompleted /> : null}
			{children}
		</DriverPaymentGatewayContext.Provider>
	);
};
