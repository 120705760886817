/* React imports */
import { useState } from "react";

/* third party imports */
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

/* Local Imports */
import AddressCard from "./AddressCard";
import { ReactComponent as PlusIcon } from "../../../assets/plus-icon.svg";
import { GET_ADDRESSES } from "../../../services/graphql/fleet/queries";
import { Location } from "../../../@types/settings";
import LocationModal from "./AddressCard/modals/LocationModal";
import { Button } from "../misc/Buttonv2";
import { ButtonSize, ButtonVariant } from "../misc/Buttonv2/types";
import { Spinner } from "../../../electrify_frontend_common/components/Spinner";

export default function Addresses() {

  const { t } = useTranslation("addresses");
  const [openAddressModal, setOpenAddressModal] = useState(false);

  const { data: { addresses = [] } = {}, loading, refetch } = useQuery<{ addresses: Location[] }, { locationType: string }>(GET_ADDRESSES, {
    variables: {
      locationType: "WORKPLACE",
    },
    onError: (err) => console.log(err),
  });

  if (loading && !addresses) {
    return <Spinner />;
  }

  return (
    <>
      {openAddressModal ? (
        <LocationModal
          isOpen={openAddressModal}
          locationType={"WORKPLACE"}
          closeModal={() => {
            refetch();
            setOpenAddressModal(false);
          }}
        />
      ) : null}
      <div className="flex flex-col w-full ">
        <div className="w-full flex flex-row  space-x-4 sm:flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-center mb-7">
          <h2 className="text-2xl font-normal">
            {t("company-addresses-title")}
          </h2>
          <Button variant={ButtonVariant.TERTIARY} size={ButtonSize.MEDIUM} onClick={() => setOpenAddressModal(true)}>
            <div className="flex items-center">
              <PlusIcon className="w-5 h-5 mr-2" />
              <span className="text-Grey-dark">{t("add-button")}</span>
            </div>
          </Button>
        </div>
        <div className="flex flex-col space-y-6 mb-10">
          <div className="col-span-6">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-3 grid-flow-row gap-8">
              {addresses?.map((item: Location) => {
                return (
                  <AddressCard
                    address={item}
                    key={item.id}
                    refetch={refetch}
                    locationType={"WORKPLACE"}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
