


import { ReactElement, useMemo } from "react";
import { VehicleImageWithFallback } from "../../VehicleImageWithFallback";
import { AlternativeEletricVehicleDetails } from "../../../types";


export function SelectedVehicleCard({
    cardHeader,
    vehicle,
    rightSideContent,
    leftSideActions,
    moreInfoClick
}: {
    cardHeader: ReactElement | null,
    vehicle: Partial<AlternativeEletricVehicleDetails>,
    rightSideContent?: ReactElement,
    leftSideActions?: ReactElement,
    rightSideActions?: ReactElement,
    moreInfoClick: (e: any) => void
}) {


    return (
        <div className={`flex w-full bg-white border border-Grey-tint rounded overflow-hidden relative h-60 `}>
            <div className="w-1/2">
                <div className={'h-[50px]'}>
                    {cardHeader}
                </div>
                <div className={`h-[150px]`}>
                    <div className="flex flex-col flex-1 justify-center bg-white items-center h-full w-full ">
                        <div className={`p-6 w-[300px] flex justify-center`}>
                            <VehicleImageWithFallback
                                onClick={moreInfoClick}
                                className="max-h-40"
                                vehicle={vehicle}
                            />

                        </div>
                    </div>
                </div>
                <div className="flex">
                    {leftSideActions}
                </div>
            </div>
            <div className={`w-1/2 flex flex-col bg-Grey-background text-Black-default p-6`}>
                {rightSideContent}

            </div>



        </div>
    );


}