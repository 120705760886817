import { useCallback, useContext, useMemo, useState } from "react";
import { Field, Form, Formik } from "formik";

import { useMutation, useQuery } from "react-query";
import { ReactComponent as TodoIcon } from "../../../../../../../assets/electrification_planner/todo_icon_new.svg";
import { ReactComponent as DoneIcon } from "../../../../../../../assets/electrification_planner/done_icon_grey.svg";
import { ReactComponent as PartiallyDone1Icon } from "../../../../../../../assets/electrification_planner/partially_done_1_icon.svg";
import { ReactComponent as PartiallyDone2Icon } from "../../../../../../../assets/electrification_planner/partially_done_2_icon.svg";
import { ReactComponent as PartiallyDone3Icon } from "../../../../../../../assets/electrification_planner/partially_done_3_icon.svg";
import { ReactComponent as NotApplicableIcon } from "../../../../../../../assets/electrification_planner/not_applicable_icon.svg";
import { ReactComponent as CalendarIcon } from '../../../../../../../assets/calendar-icon.svg';
import { ReactComponent as InformationIcon } from '../../../../../../../assets/information-icon-blueberry.svg';
import { ElectrificationPlannerAdditionalServicesSectionSubSections, ElectrificationPlannerChargingSectionSubSections, ElectrificationPlannerSections, ElectrificationPlannerStepStatus, ElectrificationPlannerStepType, ElectrificationPlannerSubSections, ElectrificationPlannerVehicleSectionSubSections } from "../../../models/Section";
import { ReactComponent as GuidelinesIcon } from "../../../../../../../assets/electrification_planner/guidelines_icon.svg";
import { ReactComponent as RecommendationIcon } from "../../../../../../../assets/electrification_planner/recommendation_icon.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as DropdownIcon } from "../../../../../../../assets/dropdown-icon.svg";
import { ReactComponent as WarningIcon } from '../../../../../../../assets/warning.svg';
import { ElectrificationPlannerStepModalStructure, SelectionType } from ".";
import { getQuestionStructure, updateElectrificationPlanner } from "../../../../../../../services/rest/electrificationPlanner";
import Input from "../../../../../../../common/components/misc/Input";
import { ElectrifyPlannerContext } from "../../../../../contexts/ElectrificationPlannerContext";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import Dropdown from "../../../../../../../electrify_frontend_common/components/Dropdown";



export function RadioButton({ name, label, value }: { name: string, label: string, value: string }) {

    return (
        <div

            className="flex items-center text-Grey-shade my-1.5 cursor-pointer">
            <Field className="mr-3
            border border-Grey-default
             checked:bg-Blueberry-dark-default
             checked:text-Blueberry-dark-default
             focus:bg-Blueberry-dark-default
             focus:text-Blueberry-dark-default
             focus:ring-0 cursor-pointer" type="radio" name={name} value={value} />
            {label}
        </div>
    )
}


export function CheckBox({ onChange, checked, label, name }: { onChange: (e: any) => void, checked: boolean, label: string, name: string }) {


    return (
        <div className="flex items-center text-Grey-shade my-1.5">
            <Field
                onChange={onChange}
                name={name}
                type="checkbox"
                checked={checked}
                className="mr-3 w-5 h-5 cursor-pointer text-Blueberry-dark-default bg-transparent rounded border-Grey-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
            />
            {label}
        </div>
    )
}

export type QuestionStructure = {
    selectionType: SelectionType,
    options: {
        text: string,
        effect: ElectrificationPlannerStepStatus
    }[],
    question: string,
    type: ElectrificationPlannerStepType,
    data: string | string[] | null,
    dueDate?: string,
    additionalInfo: {
        type: 'guideline' | 'recommendation',
        options: string[]
    }
};


export function ModalForm({
    closeModal,
    stepName,
    section,
    subsection,
    alternativeAction,
    hideRightSide
}: {
    closeModal: () => void,
    stepName: string,
    section: string,
    subsection: string,
    alternativeAction?: (val: any) => void,
    hideRightSide?: boolean
}) {

    const [questionStructure, setQuestionStructure] = useState<QuestionStructure | null>(null);


    const { isLoading } = useQuery<QuestionStructure>({
        queryKey: [stepName, 'mainModal'],
        queryFn: () => getQuestionStructure(
            section,
            subsection,
            stepName
        ),
        onSuccess: (data) => {
            setQuestionStructure(data);
        }
    });

    const addDateWhereTypeRequires = useCallback((value: any) => {
        if (questionStructure?.type === ElectrificationPlannerStepType.ACTION) {
            return { ...value, dueDate: questionStructure.dueDate || new Date().toISOString().split("T")[0] }
        }
        return value;
    }, [questionStructure?.type])


    const initialValues = useMemo(() => {
        if (questionStructure?.selectionType === SelectionType.SINGLE) {
            return { [stepName]: questionStructure.data || '' }
        }
        return { [stepName]: questionStructure?.data || [] }
    }, [questionStructure])

    if (!questionStructure || isLoading) return null;

    return (
        <ElectrificationPlannerStepModalStructure
            leftSide={<>
                {questionStructure?.selectionType === SelectionType.SINGLE ? <RadioButtonForm
                    initialValues={addDateWhereTypeRequires(initialValues)}
                    closeModal={closeModal}
                    section={section}
                    subsection={subsection}
                    possibleAnswers={questionStructure.options}
                    stepName={stepName}
                    alternativeAction={alternativeAction}
                /> :
                    <CheckboxForm
                        initialValues={initialValues}
                        closeModal={closeModal}
                        section={section}
                        subsection={subsection}
                        possibleAnswers={questionStructure.options}
                        stepName={stepName}
                    />
                }
            </>}
            rightSide={hideRightSide ? null : <div className="flex items-start">
                {questionStructure?.additionalInfo.type === 'recommendation' ?
                    <RecommendationIcon className="mr-3" />
                    : <GuidelinesIcon className="mr-3" />}

                <div>
                    <div className="text-lg text-Grey-dark mb-3">{questionStructure?.additionalInfo.type}</div>
                    <a rel="sponsored" href="https://www.awin1.com/cread.php?s=2325371&v=7605&q=355600&r=1665977">
                        <img src="https://www.awin1.com/cshow.php?s=2325371&v=7605&q=355600&r=1665977" />
                    </a>

                    <ul className="list-disc">
                        {questionStructure?.additionalInfo?.options?.map((o: string) => (
                            <li key={o} className="mt-3 text-Grey-shade ">{o}</li>
                        ))}
                    </ul>
                </div>
            </div>}

        />


    )

}

function DueDate({ value, setFieldValue }: { value: Date, setFieldValue: (field: string, value: any) => void }) {
    const { t } = useTranslation("electrificationPlanner");

    return (
        <div className="mt-8">
            <div className="flex items-center">
                <span>{t("editModal.dueDate")}</span>
                <InformationIcon className="ml-2" />
            </div>
            <Input
                name="dueDate"
                value={value.toISOString().split("T")[0]}
                onChange={(e) => {
                    setFieldValue('dueDate', e.target.value)
                }}
                className="mt-2"
                type={'date'}
                customIcon={<CalendarIcon />}
            />
        </div>
    )

}



export function RadioButtonForm({
    initialValues,
    possibleAnswers,
    closeModal,
    stepName,
    section,
    subsection,
    alternativeAction,
}: {
    initialValues: any,
    possibleAnswers: { text: string, effect: ElectrificationPlannerStepStatus }[],
    closeModal: () => void,
    stepName: string,
    section: string,
    subsection: string,
    alternativeAction?: (val: any) => void,
}) {

    const { t } = useTranslation("electrificationPlanner");

    const { refetch, electrificationPlanner } = useContext(ElectrifyPlannerContext);

    const mutation = useMutation(updateElectrificationPlanner, {
        onSuccess: () => {
            refetch();
            closeModal();
        }
    })


    const processedInitialValues = useMemo(() => {
        if (Array.isArray(initialValues[stepName])) {
            const newObj: any = {};
            newObj[stepName] = initialValues[stepName][0];
            return {
                ...newObj,
                dueDate: initialValues?.dueDate
            };
        }

        return initialValues;
    }, [initialValues])

    console.log("Processed intial values: ", processedInitialValues);


    const getIconFromEffect = (effect: ElectrificationPlannerStepStatus) => {
        switch (effect) {
            case ElectrificationPlannerStepStatus.DONE:
                return <DoneIcon className="w-[18px] h-[18px]" />
            case ElectrificationPlannerStepStatus.TODO:
                return <TodoIcon className="w-[18px] h-[18px]" />
            case ElectrificationPlannerStepStatus.PARTIALLY_DONE_1:
                return <PartiallyDone1Icon className="w-[18px] h-[18px]" />
            case ElectrificationPlannerStepStatus.PARTIALLY_DONE_2:
                return <PartiallyDone2Icon className="w-[18px] h-[18px]" />
            case ElectrificationPlannerStepStatus.PARTIALLY_DONE_3:
                return <PartiallyDone3Icon className="w-[18px] h-[18px]" />
            case ElectrificationPlannerStepStatus.NOT_APPLICABLE:
                return <NotApplicableIcon className="w-[18px] h-[18px]" />
        }
    }

    const displayEffects = possibleAnswers?.filter((v) => v.effect).length === possibleAnswers.length;

    return (
        <Formik
            initialValues={processedInitialValues}
            onSubmit={(values) => {
                alternativeAction ? alternativeAction(values) :
                    mutation.mutate({
                        section,
                        subsection,
                        data: {
                            answer: values,
                            dueDate: values?.dueDate
                        }
                    });
            }}
        >
            {({ values, setFieldValue }) => (
                <Form className="flex flex-col">

                    <div className="mb-4 text-lg">{t(`${section}.subsections.${subsection}.steps.${stepName}.question`)}</div>
                    {
                        possibleAnswers.map((value) => (
                            <div onClick={() => setFieldValue(stepName, value.text)} key={value.text} className="flex w-full items-center justify-between cursor-pointer">
                                <RadioButton
                                    key={value.text}
                                    name={stepName}
                                    label={t(`${section}.subsections.${subsection}.steps.${stepName}.options.${value.text}`)}
                                    value={value.text} />
                                <div className="ml-2">
                                    {displayEffects ? getIconFromEffect(value.effect) : null}
                                </div>
                            </div>

                        ))
                    }
                    {
                        processedInitialValues?.dueDate ?
                            <DueDate value={new Date(values?.dueDate)} setFieldValue={setFieldValue} />
                            : null
                    }
                    <>
                        {
                            electrificationPlanner ?
                                ((electrificationPlanner as any)[section as any][subsection][stepName].notApplicableBy ?
                                    <div className="flex mt-6 bg-Raspberry-default bg-opacity-30 p-3 rounded">
                                        <div className="mr-8">
                                            <WarningIcon />
                                        </div>
                                        <div className="text-sm">

                                            {t("editModal.notApplicableWarning")}
                                            <span className="font-bold"> {(electrificationPlanner as any)[section as any][subsection][stepName].notApplicableBy.sectionName}</span>
                                            <span className="font-bold"> {`-> ${(electrificationPlanner as any)[section as any][subsection][stepName].notApplicableBy.subsectionName}`}</span>
                                            <span className="font-bold"> {`-> ${(electrificationPlanner as any)[section as any][subsection][stepName].notApplicableBy.stepName}`}</span>
                                        </div>

                                    </div>
                                    : null) : null
                        }
                    </>
                    <div className="flex w-full justify-start mt-12">
                        <Button className="mr-8" type="submit" onClick={() => null}>{t("editModal.confirm")}</Button>
                        <Button variant={ButtonVariant.SECONDRY} onClick={closeModal}>{t("editModal.cancel")}</Button>
                    </div>

                </Form>

            )}

        </Formik>
    )

}

export function CheckboxForm({
    initialValues,
    possibleAnswers,
    closeModal,
    stepName,
    section,
    subsection
}: {
    initialValues: any,
    possibleAnswers: { text: string, effect: ElectrificationPlannerStepStatus }[],
    closeModal: () => void,
    stepName: string,
    section: string,
    subsection: string
}) {

    const { t } = useTranslation("electrificationPlanner");

    const { refetch } = useContext(ElectrifyPlannerContext);

    const mutation = useMutation(updateElectrificationPlanner, {
        onSuccess: () => {
            refetch();
            closeModal();
        }
    })

    const processedInitialValues = useMemo(() => {
        if (!Array.isArray(initialValues[stepName])) {
            const newObj: any = {};
            newObj[stepName] = [initialValues[stepName]];
            return newObj;
        }
        return initialValues;

    }, [initialValues])


    return (
        <Formik
            initialValues={processedInitialValues}
            onSubmit={(values) => {
                mutation.mutate({
                    section,
                    subsection,
                    data: {
                        answer: values,
                        dueDate: values?.dueDate
                    }
                });
            }}
        >
            {({ values, setFieldValue }) => (
                <Form className="flex flex-col">
                    <div className="mb-4 text-lg">{t(`${section}.subsections.${subsection}.steps.${stepName}.question`)}</div>
                    {
                        possibleAnswers.map((value) => (
                            <div onClick={() => {
                                const currentValue = values[stepName];
                                const selected = currentValue.includes(value.text);
                                if (selected) {
                                    setFieldValue(stepName, currentValue.filter((v: string) => v !== value.text));
                                    return;
                                }
                                currentValue.push(value.text);
                                setFieldValue(stepName, currentValue);
                            }} key={value.text} className="flex items-center my-2 cursor-pointer">
                                <CheckBox
                                    checked={values[stepName].includes(value.text) || false}
                                    name={stepName}
                                    onChange={()=> null}
                                    label={t(`${section}.subsections.${subsection}.steps.${stepName}.options.${value.text}`)}
                                />

                            </div>

                        ))
                    }
                    {
                        initialValues?.dueDate ?
                            <Input
                                name="dueDate"
                                value={values?.dueDate || new Date()}
                                onChange={(e) => {
                                    setFieldValue('dueDate', e.target.value)
                                }}
                                className="p-2"
                                type={'date'}
                                customIcon={<CalendarIcon />}
                            />
                            : null
                    }
                    <div className="flex w-full justify-start mt-12">
                        <Button className="mr-8" type="submit" onClick={() => null}>{t("editModal.confirm")}</Button>
                        <Button variant={ButtonVariant.SECONDRY} onClick={closeModal}>{t("editModal.cancel")}</Button>
                    </div>

                </Form>
            )}

        </Formik>
    )

}


export function GenericElectrificationPlannerDropdown({
    placeholder,
    section,
    subsection,
    stepName,
    valueSetter,
    optionsFilter
}: {
    placeholder: string | null,
    stepName: string,
    section: ElectrificationPlannerSections,
    subsection: ElectrificationPlannerVehicleSectionSubSections | ElectrificationPlannerAdditionalServicesSectionSubSections | ElectrificationPlannerChargingSectionSubSections,
    valueSetter: (value: string) => void,
    optionsFilter?: (option?: {
        text: string;
        effect: ElectrificationPlannerStepStatus;
    }) => boolean
}) {

    const { t } = useTranslation("electrificationPlanner");

    const { data: questionStructure, isLoading } = useQuery<{
        selectionType: SelectionType,
        options: { text: string, effect: ElectrificationPlannerStepStatus }[],
        question: string,
    }>(stepName, () => getQuestionStructure(
        section,
        subsection,
        stepName
    ));

    const filteredOptions = useMemo(() => {
        if (!optionsFilter) return questionStructure?.options;
        return questionStructure?.options?.filter(optionsFilter);

    }, [questionStructure?.options, optionsFilter])


    return (
        <div className={`w-1/2 mt-6`}>
            <div className="mb-2">
                <label className={"text-Grey-dark"}>{t(`${section}.subsections.${subsection}.steps.${stepName}.title`)}</label>
            </div>
            <div
                className={`border border-Grey-tint bg-white solid rounded text-base h-14 `}
            >
                <Dropdown
                    // value={placeholder}
                    placeholder={placeholder ? t(`${section}.subsections.${subsection}.steps.${stepName}.options.${placeholder}`) : null}
                    data={filteredOptions?.map((o) => ({
                        key: t(`${section}.subsections.${subsection}.steps.${stepName}.options.${o.text}`),
                        onClick: () => valueSetter(o.text),
                    })) || []}
                    icon={<DropdownIcon />}
                />
            </div>

        </div>
    )

}
