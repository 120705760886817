import { gql } from "@apollo/client";

export const GET_SIMULATION_VEHICLE_INFO = gql`
  query GetSimulationVehicleInfo {
    simulationVehicleInfo {
      vehicleId
      vehicleName
      vehicleModelName
      vehicleBrand
      vehicleModel
      yearlyMileage
      yearlyFuelAndEnergyCosts
      yearlyCo2Emission
      simulationLastUpdated
      simulationIsReady
      isVehiclePaid
      vehicleInfo {
        modelInfo {
          category
          bodyStyle
        }
        
      }
    }
  }
`;

export const GET_VEHICLE_SELECTED_EV = gql`
  query GetVehicleSelectedEv($vehicleId: ID!) {
    vehicleSelectedEv(vehicleId: $vehicleId) {
      vehicleId
      selectedEv {
        _id
        evId
        category
        bodyStyle
        make
        model
        electrificationFactor
        yearlyFuelAndEnergyCosts
        yearlyCo2Emission
        listPrice
        isSelected
        isFavorited
        notInPreference
        hasHighestScore
        hasHighestElectrificationFactor
        hasLowestFuelAndEnergyCosts
        notRecommended
      }
    }
  }
`;

export const GET_SIMULATION_PREFERENCE = gql`
  query GetSimulationPreference {
    simulationPreference {
      setTemperature
      selectedBrands
    }
  }
`;

export const GET_ALTERNATIVE_EV_BRANDS = gql`
  query GetAlternativeEvBrands {
    alternativeEvBrands
  }
`;

export const GET_ALTERNATIVE_EV_DETAILS = gql`
  query GetAlternativeEvDetails($vehicleId: ID!, $evId: String!) {
    alternativeEvDetails(vehicleId: $vehicleId, evId: $evId) {
      vehicleId
      evId
      category
      bodyStyle
      yearlyEnergyConsumption
      yearlyFuelAndEnergyCosts
      model
      make
      listPrice
      electrificationFactor
      evModelData {
        coreData {
          isAvailable
          acChargePower
          dcChargeMaxPower
          energyConsumption
        }
        technicalData {
          acceleration
          topSpeed
          totalPower
          totalPowerHp
          totalTorque
          propulsion
          vehicleConsumption
          vehicleConsumption100Km
        }
        range {
          expectedRealRangeAverage
          expectedRealRangeHighwayCold
          expectedRealRangeCityCold
          expectedRealRangeCombinedCold
          expectedRealRangeHighwayMild
          expectedRealRangeCityMild
          expectedRealRangeCombinedMild
          rangeWLTPLow
          rangeWLTPHigh
        }
        battery {
          nominalCapacity
          usableCapacity
          batteryType
        }
        charging {
          acChargePort
          acPortLocation
          acChargePower
          acChargeTime
          dcChargePort
          dcPortLocation
          dcChargePower
          dcChargeTime
        }
        dimensionsAndWeight {
          length
          width
          widthWithMirrors
          height
          wheelbase
          weightUnladen
          grossVehicleWeight
          maxPayload
          cargoVolume
          cargoVolumeMax
          cargoVolumeFrunk
        }
        miscellaneous {
          towHitchPossible
          seats
          isofixSeats
          roofRails
          turningCircle
        }
      }
    }
  }
`;

export const GET_VEHICLE_EV_ALTERNATIVES = gql`
  query GetVehicleEvAlternatives(
    $vehicleId: ID!
    $onlyFavorites: Boolean
    $sortBy: String
    $page: Int
    $pageSize: Int
    $selectedFilters: SelectedFilters
  ) {
    vehicleEvAlternatives(
      vehicleId: $vehicleId
      onlyFavorites: $onlyFavorites
      sortBy: $sortBy
      page: $page
      pageSize: $pageSize
      selectedFilters: $selectedFilters
    ) {
      vehicleId
      page
      pageSize
      totalPagesCount
      evAlternatives {
        _id
        evId
        make
        model
        category
        bodyStyle
        propulsion
        drivetrainType
        towHitchPossible
        seatsCount
        electrificationFactor
        yearlyFuelAndEnergyCosts
        yearlyCo2Emission
        listPrice
        isSelected
        isFavorited
        notInPreference
        loadWeight
        loadVolume
        offerToDisplay {
            price
            url
        }
      }
      latestSortState
      latestFilterState {
        brands
        segments
        bodyStyles
        propulsion
        seats
        towing
        availabilityStatus
        listPrice {
          min
          max
        }
        loadWeight {
          min
          max
        }
        loadVolume {
          min
          max
        }
        topSpeed
        realRange
        realConsumption
        fastCharging
        plugAndCharge
        vehicleToGrid
        isofixAvailable
        roofRails
      }
    }
  }
`;
