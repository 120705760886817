import { Button } from "../misc/Buttonv2";
import { ButtonSize, ButtonVariant } from "../misc/Buttonv2/types";

export default function ChartButtonHeader({ button1, button2, checkProp }: { button1?: any, button2?: any, checkProp?: any }) {
  return (
    <div className="flex">
      <Button
      className="mr-1"
        onClick={button1.onClick}
        variant={checkProp ? ButtonVariant.PRIMARY : ButtonVariant.SECONDRY}
        size={ButtonSize.SMALL}
      >
        <div className="text-xs py-0.5">
          {button1.label}
          {button1.vehiclesCount >= 0 && " (" + button1.vehiclesCount + ")"}
        </div>
      </Button>

      <Button
        onClick={button2.onClick}
        variant={!checkProp ? ButtonVariant.PRIMARY : ButtonVariant.SECONDRY}
        size={ButtonSize.SMALL}
      >
        <div className="text-xs">
          {button2.label}
          {button2.vehiclesCount >= 0 && " (" + button2.vehiclesCount + ")"}
        </div>
      </Button>

      {/* <button
        onClick={button1.onClick}
        className={`${checkProp
          ? "bg-Blueberry-dark-default hover:bg-Blueberry-light-shade text-white"
          : "bg-Grey-tint text-Blueberry-dark-default hover:text-white hover:bg-Grey-dark"
          } mr-2 text-xs rounded px-4 py-2.5`}>

      </button> */}
      {/* <button
        onClick={button2.onClick}
        className={`${!checkProp
          ? "bg-Blueberry-dark-default hover:bg-Blueberry-light-shade text-white"
          : "bg-Grey-tint text-Blueberry-dark-default hover:text-white hover:bg-Grey-dark"
          } text-xs rounded px-4 py-2.5`}>
        {button2.label}
        {button2.vehiclesCount >= 0 && " (" + button2.vehiclesCount + ")"}
      </button> */}
    </div>
  );
}
