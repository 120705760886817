import { ReactElement } from "react";


export function MetricsDisplay  ({
    Icon,
    value,
    units,
    property,
}: {
    Icon?: ReactElement;
    value: any;
    units: string;
    property: any;
}) {
    return (
        <div className="flex text-sm items-center">
            <div className="mr-4">{Icon}</div>
            <div className="text-Grey-dark">
                <div>
                    <span className="text-black font-bold">{value}</span> {units}
                </div>
                <div>{property}</div>
            </div>
        </div>
    );
};

export function Table({ Icon, title, data, footNote }: any) {
    return (
        <div className="w-full flex mt-12">
            <div className="w-1/3">
                <div className="flex items-center">
                    <div className="mr-4">{Icon}</div>
                    <div className="text-Blueberry-dark-default">{title}</div>
                </div>
            </div>

            <div className="w-2/3 text-sm">
                {data?.map((d: any, idx: number) => (
                    <div
                        className={`flex p-2 py-1 ${idx % 2 == 0 ? "bg-Grey-background" : null
                            }`}
                    >
                        <div className="w-2/3">{d.prop}</div>
                        <div className="w-1/3">
                            <span className="font-bold mr-1">{d.value || "-"}</span>{" "}
                            {d.value ? d.units : null}
                        </div>
                    </div>
                ))}
                <div className="text-Grey-dark mt-5 text-xs">{footNote}</div>
            </div>
        </div>
    );
}