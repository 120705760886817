import { useEffect, useMemo, useState } from "react";


import { useLazyQuery, useQuery } from "@apollo/client";
import { useQuery as useRestQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ModalHeader } from "../misc/ModalWrapper/Headers";
import { GET_BILLING_INFO, GET_UNSUBSCRIBED_VEHICLES } from "../../../services/graphql/fleet/queries";
import { createSubscriptionsForVehicles, getValidTariffForFleetUser } from "../../../services/rest/paymentProcess";
import { UnsubscribedVehicle } from "../../../@types";
import { VehicleSelection } from "./steps/VehicleSelection";
import CheckoutProcess from "./steps/CheckoutProcess";
import OrderCompleted from "./steps/OrderCompleted";
import BillingInfoModal from "./BillingInfoModal";
import { useUserData } from "../../../hooks/state/useUserData";
import { useSelectedVehicles } from "../../../hooks/utils/useSelectedVehicles";
import ModalWrapper from "../misc/ModalWrapper";

const isBillingInformationValid = (billingInfo: any) => {
    let isValid = true;

    Object.entries(billingInfo).forEach(([k, v]) => {
        console.log("Going through: ", k);
        console.log("With value: ", v);
        if ((!v || (v as any).length === 0) && k !== "billingAddressTaxId") isValid = false;
    });
    return isValid;
};




export function PaymentProcessModal({
    open,
    onCancel,
    onFinalizeProcess,
    vehicleId,
}: {
    open: boolean,
    onCancel: () => void,
    onFinalizeProcess: () => void,
    vehicleId?: string
}) {

    const { t } = useTranslation("paymentProcess");
    const { refreshUserAccountInfo } = useUserData();

    const vehiclesHook = useSelectedVehicles();

    const [isBilllingDataUpToDate, setIsBillingUpToDate] = useState(true);

    const [tariffLoading, setTariffLoading] = useState(true);

    const {
        data: unsubscribedVehiclesResponse,
        loading: unsubscribedVehiclesLoading,
        refetch: refetchUnsubscribedVehicles,
    } = useQuery(GET_UNSUBSCRIBED_VEHICLES);

    const { data: billingInfo, refetch: refetchBillingInfo } = useQuery(GET_BILLING_INFO, {
        onCompleted: (data) => {
            const billingData = data.setting;
            setIsBillingUpToDate(isBillingInformationValid(billingData));
        },
    });


    const { data: tariffResponse } = useRestQuery<any>(
        ["tariff"],
        getValidTariffForFleetUser,
        {
            onSuccess: () => {
                console.log("Got tari")
                setTariffLoading(false);
            },
            onError: (error) => {
                console.log(">>>ERR: ", error);
            },
        }
    );

    const unsubscribedVehicles = useMemo(() => {
        var unsubscribedVehicles =
            unsubscribedVehiclesResponse?.unsubscribedVehicles.slice() || [];
        return unsubscribedVehicles.sort((a: UnsubscribedVehicle, b: UnsubscribedVehicle) => {
            if (a.vehicleModelName > b.vehicleModelName) return 1;
            if (a.vehicleModelName < b.vehicleModelName) return -1;
            return 0;
        });
    },[unsubscribedVehiclesResponse]);

    const tariff = () => {
        return tariffResponse ? tariffResponse[0] : null;
    };

    const showCheckout = () => {
        setPage(1);
    };

    const confirmCheckout = async () => {
        const vehicleIdsToSubscribe = vehiclesHook.selectedVehicles.map(
            (v: UnsubscribedVehicle) => v.vehicleId
        );
        try {
            await createSubscriptionsForVehicles(tariff()?.id, vehicleIdsToSubscribe);
            setPage(2);
        } catch (e) {
            console.log("There was some error making this subscription");
        }
    };

    const closeAndRefresh = () => {
        vehiclesHook.resetSelectedVehicles();
        setPage(0);
        onCancel();
    };

    const pages = [
        {
            title: t("page-1.title"),
            message: t("page-1.message"),
            component: (
                <VehicleSelection
                    onConfirm={showCheckout}
                    unsubscribedVehicles={unsubscribedVehicles}
                    defaultSelectedVehicles={
                        vehicleId
                            ? unsubscribedVehicles.filter((v: UnsubscribedVehicle) => v.vehicleId === vehicleId)
                            : []
                    }
                    unsubscribedVehiclesLoading={unsubscribedVehiclesLoading}
                    tariffLoading={tariffLoading}
                    vehiclesHook={vehiclesHook}
                    tariff={tariff()}
                />
            ),
        },
        {
            title: t("page-2.title"),
            message: t("page-2.message"),
            component: (
                <CheckoutProcess
                    onConfirm={confirmCheckout}
                    vehiclesHook={vehiclesHook}
                    tariff={tariff()}
                />
            ),
        },
        {
            title: t("page-3.title"),
            message: t("page-3.message"),
            component: (
                <OrderCompleted
                    tariff={tariff()}
                    onConfirm={
                        onFinalizeProcess
                            ? () => {
                                vehiclesHook.resetSelectedVehicles();
                                setPage(0);
                                onFinalizeProcess();
                            }
                            : () => {
                                closeAndRefresh();
                            }
                    }
                />
            ),
        },
    ];

    const [page, setPage] = useState(0);

    useEffect(() => {
        refetchUnsubscribedVehicles();
    }, [open, refetchUnsubscribedVehicles]);

    if (!open) return null;

    return (
        <>
            {!isBilllingDataUpToDate ? (
                <BillingInfoModal
                    isOpen={!isBilllingDataUpToDate}
                    onUpdate={async () => {
                        await refreshUserAccountInfo();
                        const billingInfo = (await refetchBillingInfo())?.data?.setting;
                        const isBillingValid = isBillingInformationValid(billingInfo);
                        setIsBillingUpToDate(isBillingValid);
                    }}
                    onCancel={() => onCancel()}
                />
            ) : (
                <ModalWrapper
                    isOpen={open}
                    close={closeAndRefresh}
                    className="w-4/6">
                    <>
                        <ModalHeader
                            title={pages[page].title}
                            message={pages[page].message}
                            onCancel={closeAndRefresh}
                            isLastStep={page === 2}
                            onBack={() =>
                                page === 0 ? closeAndRefresh() : setPage(page - 1)
                            }
                        />

                        <div className="px-6 py-5 w-full overflow-auto">
                            {pages[page].component}
                        </div>
                    </>
                </ModalWrapper>
            )}
        </>
    );
}
