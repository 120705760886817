
import { useTranslation } from "react-i18next";
import { ButtonSize, ButtonVariant } from "../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { VehicleCard } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard";
import { BrandInfo } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { Metric, MetricWithComparison } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/Metric";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ArrowButton } from "../../../../../../../../electrify_frontend_common/components/ArrowButton";
import { useContext } from "react";
import { DriverEVFinderContext } from "../../contexts/DriverEVFinderContext";
import { Range } from "../../../../../../../../electrify_frontend_common/icons/Range";
import { Consumption } from "../../../../../../../../electrify_frontend_common/icons/Consumption";
import { Price } from "../../../../../../../../electrify_frontend_common/icons/Price";
import FavoriteButton from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/FavoriteButton";
import { ElectrificationFactorPlaceholder } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/ElectrificationFactor";
import { AlternativeVehicle } from "../../../../../../../../electrify_frontend_common/types";
import { FuelCostsIcon } from "../../../../../../../../electrify_frontend_common/icons/FuelCostsIcon";
import { Co2EmissionsIcon } from "../../../../../../../../electrify_frontend_common/icons/Co2EmissionsIcon";
import { SimulationVehicleInfo } from "../../../../../../../../@types";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";




export function DriverEvFinderAlternativeVehicleCard({
    userVehicle,
    alternativeVehicle,
    onClick,
}: {
    userVehicle: SimulationVehicleInfo,
    alternativeVehicle: Partial<AlternativeVehicle>,
    onClick: () => void,
}) {

    const { t } = useTranslation("evFinder");

    const {
        openEvDetailsModal,
        addNewFavoriteVehicle,
        removeFavoriteVehicle
    } = useContext(DriverEVFinderContext);

    const { localeCode } = useLayout();

    const isSelected = alternativeVehicle.isSelected;


    return (
        <VehicleCard
            vehicle={alternativeVehicle}
            moreInfoClick={(e) => {
                openEvDetailsModal(alternativeVehicle.evId || "")
            }}
            isActive={isSelected}
            additionalActions={
                <div className="flex w-full px-3 items-center justify-between">
                    <FavoriteButton
                        alternativeVehicle={alternativeVehicle}
                        addToFavorites={() => addNewFavoriteVehicle(alternativeVehicle.evId || "")}
                        removeFromFavorites={() => removeFavoriteVehicle(alternativeVehicle.evId || "")}
                        isFavorited={!!alternativeVehicle.isFavorited}
                    />
                    <ArrowButton
                        text={t("vehicleCard.moreInfo")}
                        onClick={() => openEvDetailsModal(alternativeVehicle.evId || "")}
                    />

                </div>
            }
            cardHeader={<div className="flex w-full justify-between relative">
                <div className="p-3 max-w-[90%]">
                    <BrandInfo
                        vehicleBrand={alternativeVehicle?.make}
                        vehicleModel={alternativeVehicle?.model}
                    />
                </div>
                <ElectrificationFactorPlaceholder
                    electrificationFactor={alternativeVehicle?.electrificationFactor || 0}
                />
            </div>}

            cardFooter={<div className="mb-2">
                <Metric
                    localeCode={localeCode}
                    icon={<Price className="w-4" color={isSelected ? 'white' : undefined} />}
                    value={alternativeVehicle.listPrice || 0}
                    unit={"€"}
                    tooltipTitle={t("vehicleCard.tooltips.listPrice")}
                />
                <MetricWithComparison
                    localeCode={localeCode}
                    icon={<FuelCostsIcon className="w-4" color={isSelected ? 'white' : undefined} />}
                    value={alternativeVehicle.yearlyFuelAndEnergyCosts || 0}
                    compareValue={userVehicle.yearlyFuelAndEnergyCosts || 0}
                    unit={`€ / year`}
                    tooltipTitle={t("vehicleCard.tooltips.energyCost")}
                />
                <MetricWithComparison
                    localeCode={localeCode}
                    icon={<Co2EmissionsIcon color={isSelected ? 'white' : undefined} />}
                    value={alternativeVehicle.yearlyCo2Emission || 0}
                    compareValue={userVehicle.yearlyCo2Emission || 0}
                    unit={"kg / year"}
                    tooltipTitle={t("vehicleCard.tooltips.co2Emissions")}
                />
            </div>}
            button={
                <>
                    {isSelected ?
                        <div className="w-full flex justify-center rounded p-2.5 border border-Blueberry-light-default bg-Blueberry-light-default text-white text-xs ">
                            {t("vehicleCard.button.vehicleSelected")}
                        </div> : <Button
                            variant={ButtonVariant.SECONDRY}
                            size={ButtonSize.SMALL}
                            onClick={(e) => {
                                e?.stopPropagation();
                                onClick();
                            }}
                            className="flex w-full justify-center"
                        >
                            {t("vehicleCard.button.selectVehicle")}
                        </Button>}
                </>

            }
        />
    )


}