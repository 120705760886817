import { useTranslation } from "react-i18next";
import { VehicleComparatorContext } from "../../../../../../../contexts/VehicleComparatorContext";
import { useContext } from "react";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { Price } from "../../../../../../../electrify_frontend_common/icons/Price";
import { VehicleCard } from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard";
import FavoriteButton from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/FavoriteButton";
import { ArrowButton } from "../../../../../../../electrify_frontend_common/components/ArrowButton";
import { BrandInfo } from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { ElectrificationFactorPlaceholder } from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/ElectrificationFactor";
import { Metric, MetricWithComparison } from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/Metric";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { AlternativeVehicle } from "../../../../../../../electrify_frontend_common/types";
import { Co2EmissionsIcon } from "../../../../../../../electrify_frontend_common/icons/Co2EmissionsIcon";
import { FuelCostsIcon } from "../../../../../../../electrify_frontend_common/icons/FuelCostsIcon";
import { useLayout } from "../../../../../../../hooks/state/useLayout";



export function FleetEvFinderAlternativeVehicleCard({
  alternativeVehicle,
}: {
  alternativeVehicle: Partial<AlternativeVehicle>,
}) {

  const { t } = useTranslation("evFinder")

  const { localeCode } = useLayout();

  const {
    vehicle,
    selectAlternative,
    setEvDetailQueryParams,
    addNewFavoriteVehicle,
    removeFavoriteVehicle,

  } = useContext(VehicleComparatorContext);

  const isSelected = alternativeVehicle.isSelected;


  return (
    <VehicleCard
      vehicle={alternativeVehicle}
      isActive={isSelected}
      moreInfoClick={(e) => {
        setEvDetailQueryParams({
          vehicleId: vehicle.vehicleId,
          evId: alternativeVehicle.evId || "",
        })
      }}
      additionalActions={
        <div className="flex w-full px-3 items-center justify-between">
          <FavoriteButton
            alternativeVehicle={alternativeVehicle}
            addToFavorites={() => addNewFavoriteVehicle(alternativeVehicle.evId || "")}
            removeFromFavorites={() => removeFavoriteVehicle(alternativeVehicle.evId || "")}
            isFavorited={!!alternativeVehicle.isFavorited}
          />

          <ArrowButton
            text={t("vehicleCard.moreInfo")}
            onClick={() => setEvDetailQueryParams({
              vehicleId: vehicle.vehicleId,
              evId: alternativeVehicle.evId || "",
            })}
          />
        </div>
      }
      cardHeader={<div className="flex w-full  justify-between relative">
        <div className="p-3 max-w-[80%]">
          <BrandInfo
            vehicleBrand={alternativeVehicle?.make}
            vehicleModel={alternativeVehicle?.model}
          />
        </div>
        <ElectrificationFactorPlaceholder
          electrificationFactor={alternativeVehicle?.electrificationFactor || 0}
        />
      </div>}

      cardFooter={<div className="mb-2">
        <Metric
          localeCode={localeCode}
          icon={<Price className="w-4" color={isSelected ? 'white' : undefined} />}
          value={alternativeVehicle.listPrice || 0}
          unit={"€"}
          tooltipTitle={t("vehicleCard.tooltips.listPrice")}
        />
        <MetricWithComparison
          localeCode={localeCode}
          icon={<FuelCostsIcon className="w-4" color={isSelected ? 'white' : undefined} />}
          value={alternativeVehicle.yearlyFuelAndEnergyCosts || 0}
          compareValue={vehicle.yearlyFuelAndEnergyCosts || 0}
          unit={`€ / year`}
          tooltipTitle={t("vehicleCard.tooltips.energyCost")}
        />
        <MetricWithComparison
          localeCode={localeCode}
          icon={<Co2EmissionsIcon color={isSelected ? 'white' : undefined} />}
          value={alternativeVehicle.yearlyCo2Emission || 0}
          compareValue={vehicle.yearlyCo2Emission || 0}
          unit={"kg / year"}
          tooltipTitle={t("vehicleCard.tooltips.co2Emissions")}
        />
      </div>}
      button={
        <>
          {isSelected ?
            <div className="w-full flex justify-center rounded p-2.5 border border-Blueberry-light-default bg-Blueberry-light-default text-white text-xs ">
              {t("vehicleCard.button.vehicleSelected")}
            </div> : <Button
              variant={ButtonVariant.SECONDRY}
              size={ButtonSize.SMALL}
              onClick={(e) => {
                e?.stopPropagation();
                selectAlternative(alternativeVehicle.evId || "");
              }}
              className="flex w-full justify-center"
            >
              {t("vehicleCard.button.selectVehicle")}
            </Button>}
        </>

      }
    />
  )
}