import { useTranslation } from "react-i18next";
import { FavoriteVehicleCard, FavoriteVehicleMetric } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/FavoriteVehicleCard";
import { BrandInfo } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { ArrowButton } from "../../../../../../../../electrify_frontend_common/components/ArrowButton";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { useContext } from "react";
import { DriverEVFinderContext } from "../../contexts/DriverEVFinderContext";
import FavoriteButton from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/FavoriteButton";
import { ElectrificationFactorPlaceholder } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/ElectrificationFactor";
import { AlternativeVehicle } from "../../../../../../../../electrify_frontend_common/types";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";

function DriverEvFinderFavoriteMetrics({ vehicle }: { vehicle: Partial<AlternativeVehicle> }) {

    const { selectedAlternativeElectricVehicle } = useContext(DriverEVFinderContext)

    const { t } = useTranslation("evFinder");

    const { localeCode } = useLayout();

    const isSelected = selectedAlternativeElectricVehicle?.evId === vehicle.evId;

    console.log("Vehicle: ", vehicle);

    return (
        <div className={isSelected ? 'bg-Blueberry-dark-default' : ''}>
            <div>
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.listPrice")}
                    value={vehicle.listPrice || "0"}
                    unit={"€"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.energyCost")}
                    value={vehicle.yearlyFuelAndEnergyCosts || "0"}
                    unit={"€ / year"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.co2Emissions")}
                    value={vehicle.yearlyCo2Emission || "0"}
                    unit={"kg / year"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.availabilityStatus")}
                    value={vehicle.availableNow ? "Available" : "Comming soon"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.loadWeight")}
                    value={vehicle.loadWeight || "0"}
                    unit={"kg"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.loadVolume")}
                    value={vehicle.loadVolume || "0"}
                    unit={"l"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.countOfSeats")}
                    value={vehicle.seatsCount || "0"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.drivetrainPropulsion")}
                    value={vehicle.propulsion || "?"}
                    isSelected={isSelected}
                />
            </div>
            <div className="pt-4">
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.towingCapacity")}
                    value={vehicle.towHitchPossible ? "Yes" : "No"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.totalTorque")}
                    value={vehicle.totalTorque || "0"}
                    unit={"Nm"}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.expectedRealRange")}
                    value={vehicle.range || "0"}
                    unit="km"
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.vehicleConsumption")}
                    value={vehicle.energyConsumption || "0"}
                    unit="kWh"
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.acChargeSpeed")}
                    value={vehicle.acChargeSpeed || "N.A"}
                    unit={vehicle.acChargeSpeed ? "km / h" : ""}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.acChargePower")}
                    value={vehicle.acChargePower || "N.A"}
                    unit={vehicle.acChargePower ? "kWh" : ""}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.dcChargeSpeed")}
                    value={vehicle.dcChargeSpeed || "N.A"}
                    unit={vehicle.dcChargeSpeed ? "km / h" : ""}
                    isSelected={isSelected}
                />
                <FavoriteVehicleMetric
                    localeCode={localeCode}
                    label={t("vehicleCard.favoritesMetrics.dcChargePower")}
                    value={vehicle.dcChargePower || "N.A"}
                    unit={vehicle.dcChargePower ? "kWh" : ""}
                    isSelected={isSelected}
                />
            </div>

        </div>
    )

}

export function DriverEvFinderFavoriteVehicleCard({
    alternativeVehicle,
    onClick
}: {
    alternativeVehicle: Partial<AlternativeVehicle>,
    onClick: () => void
}) {

    const { t } = useTranslation("evFinder")

    const {
        openEvDetailsModal,
        addNewFavoriteVehicle,
        removeFavoriteVehicle
    } = useContext(DriverEVFinderContext)

    const isSelected = alternativeVehicle.isSelected;


    return (
        <FavoriteVehicleCard
            footerBgColor={isSelected ? 'bg-Blueberry-dark-default' : undefined}
            moreInfoClick={(e) => {
                e.stopPropagation();
                openEvDetailsModal(alternativeVehicle.evId || "");
            }}
            vehicle={alternativeVehicle}
            cardHeader={<div className="flex w-full  justify-between relative">
                <div className="p-3 max-w-[90%]">
                    <BrandInfo
                        vehicleBrand={alternativeVehicle?.make}
                        vehicleModel={alternativeVehicle?.model}
                    />
                </div>
                <ElectrificationFactorPlaceholder
                    electrificationFactor={alternativeVehicle?.electrificationFactor || 0}
                />
            </div>}
            // banner={{
            //     info: "Leasing from 300 € / month",
            //     onClick: () => null
            // }}
            additionalActions={<div className="flex w-full justify-between px-3">
                <FavoriteButton
                    alternativeVehicle={alternativeVehicle}
                    addToFavorites={() => addNewFavoriteVehicle(alternativeVehicle.evId || "")}
                    removeFromFavorites={() => removeFavoriteVehicle(alternativeVehicle.evId || "")}
                    isFavorited={!!alternativeVehicle.isFavorited}
                />

                <ArrowButton
                    text={t("vehicleCard.moreInfo")}
                    onClick={(e) => {
                        e?.stopPropagation();
                        openEvDetailsModal(alternativeVehicle.evId || "");
                    }}
                />
            </div>}

            cardFooter={<DriverEvFinderFavoriteMetrics vehicle={alternativeVehicle} />}
            button={
                <>
                    {isSelected ?
                        <div className="w-full flex justify-center rounded p-2.5 bg-Blueberry-light-default text-white text-xs mb-2">
                            {t("vehicleCard.button.vehicleSelected")}
                        </div>
                        : <Button
                            variant={ButtonVariant.SECONDRY}
                            size={ButtonSize.SMALL}
                            onClick={(e) => {
                                e?.stopPropagation();
                                onClick();
                            }}
                            className="flex w-full justify-center mb-2"
                        >
                            {t("vehicleCard.button.selectVehicle")}
                        </Button>}
                </>

            }
        />
    )


}