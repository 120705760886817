import { GenericSlider } from "../../GenericSlider";



export function FilterSlider({
    value,
    step,
    onChange,
    min,
    max,
    symbol
}: {
    value: [number | null, number | null],
    step: number
    onChange: (newVal: number[]) => void,
    min: number,
    max: number,
    symbol: string
}) {


    return (
        <GenericSlider
            min={min}
            max={max}
            value={[
                value[0] || min,
                value[1] || max,
            ]}
            step={step}
            onChangeMain={(e: any, newVal: any) => {
                let [newValMin, newValMax] = newVal;

                if (newValMin <= min) newValMin = null;
                if (newValMax >= max) newValMax = null;
                onChange([newValMin, newValMax])
                // onChange(newVal);
            }}
            symbol={symbol}

        />
    )


}