import { useEffect, useRef, useState } from "react";
import { useVehicleSimulation } from "./hooks/useVehicleSimulation";
import { SelectionWrapper } from "./components/SelectionWrapper";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import { Spinner } from "../../../../../../electrify_frontend_common/components/Spinner";
import { DriverEVFinderContextProvider } from "./contexts/DriverEVFinderContext";



export function DriverEvFinder() {

    const { t, i18n } = useTranslation("evFinder")
    const language = i18n.language;

    const { setHeader } = useLayout();


    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("title"),
            defaultHeaderDescription: t("descriptionBC")
        })
    }, [language])

    const { vehicleSelectionState, vehicleSelectionQueriesAndMutations } = useVehicleSimulation();
    const vehicleLoading = vehicleSelectionQueriesAndMutations.vehicleLoading;
    const driverVehicle = vehicleSelectionState.originalDriverVehicle.value;


    return (<>
        {
            driverVehicle ?
                <DriverEVFinderContextProvider vehicle={driverVehicle}>
                    <div className="p-10 px-4">
                        <SelectionWrapper
                            vehicle={driverVehicle}
                            vehicleLoading={vehicleLoading}
                        />
                    </div>
                </DriverEVFinderContextProvider>
                : <Spinner />
        }
    </>)


}